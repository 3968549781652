<template>
 <main class="dashboard">
    <div class="card">
      <div class="card-body text-center m-5">
        <div class="h1">
            <i class="bi bi-wifi-off fz80"></i>
        </div>
        <h1>Ведутся технические работы</h1>
      </div>
      <div class="game">
<Sapper></Sapper>
      </div>
    </div>

  </main>
</template>
<script>
import Sapper from "@/components/Sapper.vue";
import HTTP from "@/api/common";
export default {
    name: 'InDev',
  components: {Sapper},
    data() {
      return {

      }
    },
  mounted() {
      setInterval(()=>{
        HTTP.get(`status/`).then((response) => (response.status === 200)?location.pathname = '/':"").catch((e)=>console.warn(e));
      }, 1000*30)
  }

}

</script>
<style scoped>
.fz80{
  font-size: 80px;
}
</style>