import { HTTP } from "./common";

export const UserApi = {
  get_info() {
    return HTTP.get(`/staff/get_info/`);
  },
    restore_password(email){
    return HTTP.post('/auth/restore_password/', {email: email})
  },
  check_token(uid, token){
    return HTTP.post('/auth/check_token/', {uid: uid, token: token})
  },

  change_password(data) {
    return HTTP.post("/auth/change_password/", data);
  },

  update_password(data) {
    return HTTP.post("/users/update_password/", data);
  },
  update_user(data) {
    return HTTP.post("/users/update_user/", data);
  },
  managers(data) {
    return HTTP.get(`/staff/managers/?${new URLSearchParams(data)}`,  ).then((response) => {
      return response.data;
    });
  },
  add_favorite(project_id) {
    return HTTP.post("/staff/add_favorite/", {project_id: project_id}).then((response) => {
      return response.data;
    });
  },
  server_info(data) {
    return HTTP.get("/staff/server_info/").then((response) => {
      return response.data;
    });
  },
  get_code() {
    return HTTP.get("/staff/get_tg_secret_code/").then((response) => {
      return response.data;
    });
  },
  settings() {
    return HTTP.get("/staff/get_settings/").then((response) => {
      return response.data;
    });
  },
  set_settings(data) {
    return HTTP.post("/staff/set_settings/", data).then((response) => {
      return response.data;
    });
  },
  save_settings(data) {
    return HTTP.post("/staff/save_settings/", data).then((response) => {
      return response.data;
    });
  },
};
