import {reactive} from "vue";

import store from "./store"

export const state = reactive({
    connected: false,
    report: null,
    alert: null,
    authy: null,
    doc: null,
    tg_settings: null,
});
let user_id = reactive(null);

export const wsClient = {
    base_url: `${location.protocol.includes("https") ? "wss" : "ws"}://${process.env.NODE_ENV === "development" ? process.env.VUE_APP_WS_URL : location.host}/ws/`,
    chatSocket: {},
    sendWs(message) {
      this.waitForConnection(() => this.chatSocket.send(message), 1000);
    },
    waitForConnection(callback, interval) {
      const $this = this;
      if ($this.chatSocket.readyState === 1) {
        callback()
      } else {
        setTimeout(function () {
          $this.waitForConnection(callback, interval);
        }, interval);
      }
    },
    close(){
        this.chatSocket.close()
    },
    connectWs(path) {
        const $this = this;
        setTimeout(function () {
            user_id = store?.getters?.user?.id;
            $this.chatSocket = new WebSocket(
                `${$this.base_url}${path}/${user_id}/`
            );

            $this.chatSocket.onmessage = function (e) {
                let data = JSON.parse(e.data)
                state[data.content_type] = data.object;
            };

            $this.chatSocket.onclose = function (e) {
                if (user_id.value) {
                    setTimeout(function () {
                        $this.connectWs();
                    }, 1000);
                }
            };
            $this.chatSocket.onerror = function (err) {
                console.error('Socket encountered error: ', err.message, 'Closing socket');
                $this.chatSocket.close();
            };

        }, 1500)
    }
}