<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Скриншоты выдачи</h2>
      <BackButton></BackButton>
    </div>
    <div class="filters mb-5" v-if="project">
      <div class="d-flex filters">
        <MultiSelect
            v-model="keyword"
            :options="project.keywords"
            :multi="true"
            :only_value="true"
            name="Запросы"></MultiSelect>


        <CountryChoice v-bind:active_country.sync="project.active_country"
                       v-bind:project_id="project.id"
                       v-bind:countries="project.countries"
                       v-bind:changeCountry="changeCountry"></CountryChoice>
        <date-picker v-model="active_date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false"></date-picker>

      </div>

    </div>

    <div class="screen-list" v-if="project">
      <div class="header">
        <div class="keyword">Запрос</div>
        <div class="column" v-for="date in week">
          {{ $moment(date).format('DD/MM/YYYY') }}<span class="text-secondary ms-2"
                                                        style="text-transform: uppercase;">{{
            $moment(date).format('dd')
          }}</span>
        </div>
      </div>
      <template v-for="key in keywords">
        <div class="screen-item">
          <div class="keyword key-name">{{ key.name }}</div>
          <div class="column" v-for="(date, index) in key.screen">
            <div class="row">
              <div class="col-1" v-if="!index"></div>
              <div class="col engine" v-for="engine in $ENGINES"><i v-if="date?.google?.length"
                                                                    :class="'ico '+ engine"></i></div>
            </div>
            <div class="row no-wrap">
              <template v-for="(engine, en_index) in $ENGINES">
                <div class="col-1 screens" v-if="!index && !en_index">
                  <div v-for="x in Math.max(...key.screen.map(x=>[x.yandex.length, x.google.length]).flat())">{{ x }}
                  </div>
                </div>
                <div class="col screens">
                  <template v-if="date[engine]?.length">
                    <div class="screen" v-for="g in date[engine]">
                      <a :href="g" target="_blank">html</a>
                    </div>
                  </template>
                </div>
              </template>
            </div>

          </div>

        </div>
      </template>
    </div>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import MultiSelect from "@/plugins/Select.vue";
import moment from "moment/moment";

export default {
  name: 'SearchScreen',
  components: {
    MultiSelect
  },
  data() {
    return {
      project: null,
      screens: [],
      week: [],
      allowedDates: [],
      active_date: null,
      old_active_date: null,
      keyword: []

    }
  },
  computed: {
    keywords() {
      let keywords = this.project.keywords;
      if (this.keyword.length) {
        keywords = keywords.filter(x => this.keyword.indexOf(x.id) + 1)
      }
      return keywords;
    }
  },
  watch: {

    active_date: function (val) {
      if (this.old_active_date !== null) this.getScreen();
      this.old_active_date = val;
    },
  },
  mounted: function () {
    this.getScreen()
    ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)

    let current = new Date()
    let week = [];
    current.setDate((current.getDate() - current.getDay() + 1));
    for (let i = 0; i < 7; i++) {
      week.push(
          new Date(current)
      );
      current.setDate(current.getDate() + 1);
    }
    this.week = week;


  },
  methods: {

    getScreen: function () {
      let $this = this;
      ProjectApi.screen_list(this.$route.params.id, this.active_date).then(response => {
        $this.project = response;
        if (!$this.active_date) {
          $this.active_date = moment($this.project.last_parser).format("YYYY-MM-DD");
        }
      })
    },
    changeCountry: function (country) {
      if (this.project.active_country.code !== country.code) {
        this.project.active_country = country;
        this.getScreen();
      }
    }

  }
}

</script>

<style scoped lang="scss">

.screen-list {
  overflow-x: scroll;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .column {
    width: 140px !important;
    flex: 0 0 auto;
    text-align: center;

    .engine {
      text-align: center;
    }

    .screens {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;

      .screen {
        display: flex;
        gap: 10px;
      }
    }

  }

  .header {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
  }

  .screen-item {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    border-bottom: 1px solid rgba(139, 139, 139, 1);
    width: max-content;
    padding-bottom: 10px;
  }

  .keyword {
    width: 250px;
    flex: 0 0 auto;
    position: sticky;
    left: 0;
    background: #fff;
    display: flex;
    align-items: center;
  }
}

</style>
