import ProjectAdd from '@/views/projects/edit/ProjectAdd.vue'
import ProjectTRSetting from '@/views/projects/edit/ProjectTRSetting.vue'
import TonOnlineSettings from '@/views/projects/edit/TonOnlineSettings.vue'

import ProjectDetail from '@/views/projects/detail/ProjectDetail.vue'
import LinkContent from '@/views/links/LinkContent.vue'
import LinksInfo from '@/views/links/LinksInfo.vue'
import ImageInfo from '@/views/links/ImageInfo.vue'
import ProjectStat from '@/views/projects/ProjectStat.vue'

import ProjectDetailImages from '@/views/projects/images/ProjectDetailImages.vue'
import ProjectImages from '@/views/projects/images/ProjectImages.vue'
import DonorService from '@/views/projects/donors/DonorService.vue'
import DonorAdd from '@/views/projects/donors/DonorAdd.vue'

import RelatedRequests from '@/views/projects/related_requests/RelatedRequests.vue'
import AlsoAsk from '@/views/projects/also_ask/AlsoAsk.vue'

import FullProjectInfo from '@/views/projects/FullProjectInfo.vue'
import LinkHistory from '@/views/links/LinkHistory.vue'
import Hints from '@/views/projects/hints/Hints.vue'
import HintScreen from '@/views/projects/hints/HintScreen.vue'
import SearchScreen from '@/views/projects/SearchScreen.vue'
import ProjectPrognoz from "@/views/forecast/ProjectPrognoz.vue";
import FactForecast from "@/views/forecast/FactForecast.vue";
import EditForecast from "@/views/forecast/EditForecast.vue";
import DailySearch from "@/views/projects/DailySearch.vue";
import KnowledgeView from "@/views/projects/knowledge/KnowledgeView.vue";
import WikipediaView from "@/views/projects/wikipedia/WikipediaView.vue";
import WikipediaDetail from "@/views/projects/wikipedia/WikipediaDetail.vue";
import DomainStat from "@/views/projects/domains/DomainStat.vue";
import DomainDetail from "@/views/projects/domains/DomainDetail.vue";
import PdfDB from "@/views/projects/PdfDB.vue";
////pictures
import ProjectReports from '@/views/projects/reports/ProjectReports.vue'
import Pictures from '@/views/projects/pictures/Pictures.vue'
import UniqPicture from '@/views/projects/pictures/PictureUniq.vue'

import Video from '@/views/projects/video/Video.vue'
import VideoUniq from '@/views/projects/video/VideoUniq.vue'

import BacklinksView from '@/views/projects/backlinks/BacklinksView.vue'

import CorrectLinks from '@/views/projects/detail/CorrectLinks.vue'

import BacklinkStat from '@/views/projects/backlinks/BacklinkStatView.vue'


export default [

    {
        path: '/projects/stats',
        name: 'full_info',
        component: FullProjectInfo,
        meta: {
            requiresAuth: true
        }
    },


    {
        path: '/projects/add',
        name: 'add_project',
        component: ProjectAdd,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },

    {
        path: '/projects/:id/edit',
        name: 'project_edit',
        component: ProjectAdd,
        meta: {
            requiresAuth: true,
            title: "Настройки проекта"
        }
    },

    {
        path: '/projects/:id/links/:link_id/contents',
        name: 'link_content',
        component: LinkContent,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },
    {
        path: '/projects/:id/links/:link_id/history',
        name: 'link_history',
        component: LinkHistory,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },

    {
        path: '/projects/:id/tr_setting',
        name: 'project_tr_setting',
        component: ProjectTRSetting,
        meta: {
            requiresAuth: true,
            title: "Настройки для ton-reputation"
        }
    },

    {
        path: '/projects/:id/ton-online',
        name: 'project_ton_online_setting',
        component: TonOnlineSettings,
        meta: {
            requiresAuth: true,
            title: "Настройки для интеграциия с ton-online"
        }
    },


    {
        path: '/projects/backlink_stat',
        name: 'backlink_stat',
        component: BacklinkStat,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },

    {
        path: '/projects/:id/uniq_images',
        name: 'project_images',
        component: ProjectImages,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },
    {
        path: '/projects/:id/info',
        name: 'links_info',
        component: LinksInfo,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },
    {
        path: '/projects/:id/info/images',
        name: 'images_info',
        component: ImageInfo,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },
    {
        path: "/projects/:id/daily",
        name: "project_daily_search",
        component: DailySearch,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        },
    },
    {
        path: "/projects/:id/forecast",
        name: "project_prognoz",
        component: ProjectPrognoz,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects/forecast/stat/",
        name: "forecast_stat",
        component: FactForecast,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects/:id/forecast/edit/",
        name: "forecast_edit",
        component: EditForecast,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/projects/:id/stat',
        name: 'project_stat',
        component: ProjectStat,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/projects/:id/pictures',
        name: 'project_pictures',
        component: Pictures,
        meta: {
            requiresAuth: true,
            permissions: ['serm', 'audit']
        }
    },


    {
        path: '/projects/:id/pictures/uniques',
        name: 'project_pictures_uniques',
        component: UniqPicture,
        meta: {
            requiresAuth: true,
        }
    },

    {
        path: '/projects/:id/video',
        name: 'project_video',
        component: Video,
        meta: {
            requiresAuth: true,
            hasPerm: true,
            title: 'Блок видео'
        }
    },


    {
        path: '/projects/:id/video/uniques',
        name: 'project_video_uniques',
        component: VideoUniq,
        meta: {
            requiresAuth: true,
            title: 'Уникальные изображения'
        }
    },
    {
        path: '/projects/:id',
        name: 'project_detail',
        component: ProjectDetail,
        meta: {
            requiresAuth: true,
            hasPerm: true,
            title: 'Органическая выдача'
        },
        children: []
    },
    {
        path: '/projects/:id/images',
        name: 'project_images_info',
        component: ProjectDetailImages,
        meta: {
            requiresAuth: true,
            hasPerm: true,
            title: 'Выдача изображений'
        }
    },

    {
        path: "/projects/:id/reports",
        name: "project_reports",
        component: ProjectReports,
        meta: {
            requiresAuth: true,
            hasPerm: true,
            title: 'Отчёты'
        },
    },

    {
        path: '/projects/:id/hints',
        name: 'project_hints',
        component: Hints,
        meta: {
            requiresAuth: true,
            hasPerm: true,
            title: 'Подсказка'
        }
    },
    {
        path: '/projects/:id/hints/screen',
        name: 'hint_screen',
        component: HintScreen,
        meta: {
            requiresAuth: true,
            title: 'Скриншоты подсказок',
            h1: 'Скриншоты подсказок'
        }
    },

    {
        path: '/projects/:id/search_screen',
        name: 'search_screen',
        component: SearchScreen,
        meta: {
            requiresAuth: true,
            permissions: ['serm']
        }
    },
    {
        path: '/projects/:id/donors',
        name: 'donors',
        component: DonorService,
        meta: {
            requiresAuth: true,
            title: 'Задачи на доноров',
            h1: 'Задачи на доноров'
        }
    },
    {
        path: '/projects/:id/donors/:link_id',
        name: 'donors_link',
        component: DonorAdd,
        meta: {
            requiresAuth: true,
            title: 'Задачи на доноров',
            h1: 'Задачи на доноров'
        }
    },

    {
        path: '/projects/:id/wikipedia/',
        name: 'project_wikipedia',
        component: WikipediaView,
        meta: {
            requiresAuth: true,
            hasPerm: true
        }
    },

    {
        path: '/projects/:id/wikipedia/:wiki_id/',
        name: 'project_wikipedia_detail',
        component: WikipediaDetail,
        meta: {
            requiresAuth: true,
        }
    },

    {
        path: '/projects/:id/knowledge',
        name: 'project_knowledge_block',
        component: KnowledgeView,
        meta: {
            requiresAuth: true,
            hasPerm: true
        }
    },

    {
        path: '/projects/:id/related_requests/',
        name: 'project_related_requests',
        component: RelatedRequests,
        meta: {
            requiresAuth: true,
            hasPerm: true
        }
    },


    {
        path: '/projects/:id/also_ask/',
        name: 'project_also_ask',
        component: AlsoAsk,
        meta: {
            requiresAuth: true,
            hasPerm: true
        }
    },

    {
        path: '/projects/:id/backlinks',
        name: 'project_backlinks',
        component: BacklinksView,
        meta: {
            requiresAuth: true,
            hasPerm: true
        }
    },

    {
        path: '/projects/:id/correct_links',
        name: 'correct_links',
        component: CorrectLinks,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/projects/:id/domains',
        name: 'domains_stat',
        component: DomainStat,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/projects/:id/domains/:domain_id',
        name: 'domain_detail',
        component: DomainDetail,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/projects/:id/pdf_db',
        name: 'pdf_db',
        component: PdfDB,
        meta: {
            requiresAuth: true
        }
    },
]
