<template>
  <main class="dashboard">

    <div class="user-info d-flex">
      <object class="avatar" :data="user.picture" type="image/jpeg"></object>
      <div class="info">
        <div class="name">
        <h3>{{ user.fullname }}</h3>
        <h5>{{ user.email }}</h5>
          </div>

        <button class="btn btn-outline-dark" v-if="!settings.telegram" @click.prevent="getCode">Подписаться на уведомления <i class="bi bi-telegram"></i></button>
        <div class="area" v-if="code">
          <h5>Отправьте код в сообщениях с ботом <a :href="code.bot" target="_blank">{{code.bot}}</a></h5>
          <h1 @click="copyCode()" style="text-align: center; cursor: pointer;">{{code.code}}</h1>
        </div>

        <div class="d-flex" style="gap: 20px" v-if="settings.telegram">
          <div class="alerts">
            <div class="checkbox" v-for="(alert, key) in alerts">
              <input @change="saveSettings" :id="`id_`+key"  type="checkbox" v-model="settings.telegram.settings" :value="key">
              <label :for="`id_`+key">
                {{ alert }}
              </label>
            </div>

          </div>

        </div>
        <a href="https://t.me/+Db9yzqTN4o1mODhi" target="_blank"> <i class="bi bi-telegram"></i> Мониторинг сайтов</a>
        <a href="" target="_blank" @click.prevent="change_pass=!change_pass"><i class="bi bi-shield-shaded"></i>Сменить пароль</a>
      </div>
    </div>


    <div class="card" v-if="change_pass">


      <div class="card-body">
        <h4>Изменить пароль</h4>
        <div class="row">
          <div class="col-sm-5 m-3">


            <form @submit="changePassword" class="form-box flex-list step-32">
              <div class="form-floating mb-2 password">
                <input class="form-control" id="oldPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="oldPassword" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <label for="oldPassword">Текущий пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in baseErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2 password">
                <input class="form-control " id="c" :type="show_pass?'text':'password'" name="" required
                       v-model="newPassword" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <label for="newPassword">Новый пароль</label>
                <div class="form-group__message">
                  <div class="error" v-for="error in newErrors">- {{ error }}</div>
                </div>
              </div>
              <div class="form-floating mb-2 password">
                <input class="form-control " id="repeatPassword" :type="show_pass?'text':'password'" name="" required
                       v-model="repeatPassword" placeholder="Пароль">
                <label for="repeat">Повторите новый пароль</label>
                <i v-on:click="show_pass=!show_pass"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <div class="form-group__message">
                  <div class="error" v-for="error in repeatErrors">- {{ error }}</div>
                </div>
              </div>


              <div class="form-floating mb-2">
                <button class="w-100 btn btn-dark" type="submit">Изменить пароль</button>
              </div>


            </form>
          </div>
        </div>


      </div>
    </div>

  </main>


</template>

<script>
import {UserApi} from '@/api/user'
import passwordMixin from "./password_mixins";
import {state} from "@/socket";

export default {
  name: 'Settings',
  data(){
    return {
      change_pass: false,
      settings: {},
      code: null,
      alerts: {}
    }
  },
  mixins: [passwordMixin],
  computed: {
    user: {
      get() {
        return this.$store.getters.user
      },
      set(value) {
        this.$store.dispatch('update_user', value)
      }
    },
     state_tg_settings(value) {
      return state.tg_settings
    },
  },
  watch: {
    state_tg_settings(value) {
      this.settings = value;
      this.code = null;
    },
  },
  mounted() {
    const $this = this;
    UserApi.settings().then(response=>{
      $this.settings = response.settings
      $this.alerts = response.alerts
    })
  },
  methods: {
    copyCode() {
      try {
        navigator.clipboard.writeText(this.code.code);
        this.$notify({
          type: 'success',
          text: "Добавленно в буфер обмена"
        });
      }
      catch ($e) {
        console.error("can't copy")
      }
    },
    saveSettings(){
       UserApi.save_settings(this.settings.telegram)
    },
    getCode(){
      UserApi.get_code().then(resp=>this.code=resp)
    },
    changePassword: function (e) {
      e.preventDefault();
      let $this = this;
      if (!this.repeatErrors.length && !this.repeatErrors.length) {
        this.baseErrors = [];
        UserApi.update_password({
          old: this.oldPassword,
          newp: this.newPassword,
          repeat: this.repeatPassword
        }).then(response => {
          if (response.data['errors']) {
            $this.baseErrors = response.data['errors'];
          } else {
            let username = $this.user.email, password = $this.newPassword;
            this.$notify({
              type: 'success ',
              text: 'Пароль обновлён'
            });
            this.change_pass = false;
            this.$store.dispatch('login', {
              username,
              password
            }).then(response => {

            });
          }
        })
      }
    }
  }
}

</script>
<style scoped lang="scss">
.bi-eye{
  color: black!important;
}
.user-info {
  gap: 20px;

  object {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background: rgb(166, 164, 164);
  }
  .info{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .alerts{
    column-count: 2;
  }
}
</style>
