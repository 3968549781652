<template>
  <main class="dashboard">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h1>Проверка позиций {{ domain?.name }}</h1>
      <BackButton></BackButton>
    </div>
    <div class="filters">
      <div class="dropdown">
        <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
          <i :class="engine"></i> {{ engine }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="eng in engines"><a class="dropdown-item" @click.prevent="engine=eng" href="#">{{ eng }}</a>
          </li>
        </ul>
      </div>
      <div class="dropdown">
        <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
          <div class="countries">
                <span class="flag">
              <i :class="`${active_country?.code?.toLowerCase()}`"></i>
              </span>
          </div>
          {{ active_country.name }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="country in project?.countries">
            <a class="dropdown-item" @click.prevent="active_country=country"
               href="#">
              <div class="countries">
                <span class="flag">
              <i :class="`${country?.code?.toLowerCase()}`"></i>
              </span>
              </div>

              {{ country.name }}</a>
          </li>
        </ul>

      </div>
      <div class="dropdown">
        <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
          {{ date_types[date_type] }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="(value, key) in date_types">
            <a class="dropdown-item" @click.prevent="date_type=key"
               href="#">
              {{ value }}
            </a>
          </li>
        </ul>

      </div>

      <date-picker
          v-model="dates"
          :multi-dates="date_type==='free'?{ limit: 5}:false"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          :range="date_type!=='free'"
          model-type="yyyy-MM-dd"
          :auto-apply="date_type!=='free'"
          :allowed-dates="allowedDates"
          :enable-time-picker="false"
          :multi-calendars="date_type!=='free'"
      ></date-picker>
      <button class="btn btn-sm btn-dark" @click="getReport()">
        <i class="bi bi-file-arrow-down"></i>
      </button>

    </div>
    <div id="legend-container" class="legend"></div>
    <div style="height: 430px" class="d-flex">
      <div style="width: 80%">
        <Bar v-if="datacollection"
             :options="chartOptions"
             :data="datacollection"
             :plugins="[htmlLegendPlugin]"
        />
      </div>
      <div>
        <div id="legend-container-1" class="legend"></div>
        <Doughnut :data="data_doughnut" :options="{
  responsive: true,
  maintainAspectRatio: false,
   plugins: {
          htmlLegend: {
            containerID: 'legend-container-1',
          },
          legend: {
            display: false,
          },
          datalabels: {
            formatter: function (value, context) {
              return !!value?value:'';
            }
          }
        }
}
"/>
      </div>
    </div>
    <div class="stats">
      <div class="graph" v-if="stat_medium?.donut">
        <Doughnut :data="stat_medium?.donut" :options="{
  responsive: true,
  maintainAspectRatio: false,
}
"/>
      </div>
      <div class="stat">
        <div class="place up">
          <span><b>&uarr;</b>{{ stat_medium?.up?.count }}</span>{{ stat_medium?.up?.percent }}%
        </div>
        <div class="place no-change">
          <span><b>=</b>{{ stat_medium?.no_change?.count }}</span>{{ stat_medium?.no_change?.percent }}%
        </div>
        <div class="place down">
          <span><b>&darr;</b>{{ stat_medium?.down?.count }}</span>{{ stat_medium?.down?.percent }}%
        </div>
      </div>
      <div class="props">
        <template v-for="value in stat_positions">
          <div class="prop">
            <div class="name" :style="{background: value.name[2]}">
              {{ value.name[0] }} - {{ value.name[1] }}
            </div>
            <div class="data">
              <span>{{ value.percent }}</span>
              <span>{{ value.len }}</span>
              <span :class="'text-'+(value.change>=0?'success':'danger')">
                {{ value.change }}</span>

            </div>

          </div>
        </template>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered" id="statTable">
        <thead>
        <tr v-if="!copy">
          <th>
            <input type="text" v-model="key_q" class="form-control" placeholder="Поиск по запросам">
          </th>
          <th :colspan="table_dates.length">
            <select v-model="color_type" class="form-control" name="" id="">
              <template v-for="(key, value) in color_types">
                <option :value="value">{{ key }}</option>
              </template>
            </select>
          </th>
        </tr>
        <tr id="statHead">
          <th scope="col" class="sticky">
            Запросы <span class="text-secondary">({{ keywords.length }})</span>
            <button class="btn btn-sm" @click.prevent="copyTable"><i class="bi bi-clipboard"></i></button>
          </th>
          <template v-for="date in JSON.parse(JSON.stringify(table_dates)).reverse()">
            <th scope="col" class="no-wrap">
              <span @click="setOrder(date)"
                    :class="(order===date?(!order_direction?' active-sort':' danger-class'):'')">{{
                  $moment(date).format("DD.MM.YYYY")
                }}</span>
              <div class="period-params-table" v-if="!copy">
                <div class="dropdown">
                  <button class="btn btn-outline-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                          aria-expanded="false">
                    {{ choice_period_table[0] }} - {{ choice_period_table[1] }}
                  </button>
                  <ul class="dropdown-menu">
                    <li v-for="value in JSON.parse(JSON.stringify(period_params)).reverse()">
                      <a class="dropdown-item" @click.prevent="choice_period_table=value"
                         href="#">
                        {{ value[0] }} - {{ value[1] }}
                      </a>
                    </li>
                  </ul>

                </div>
                <div class="percent">
                  {{ getParamTable(date) }}%
                </div>
              </div>
            </th>
          </template>
        </tr>
        </thead>
        <tbody class="table-group-divider" id="statBody">
        <tr v-for="keyword in table_positions">
          <td scope="row" class="no-wrap sticky text-start">{{ keyword.name }}</td>
          <template v-for="date in JSON.parse(JSON.stringify(table_dates)).reverse()">
            <td :class="getClass(keyword[date])">
             <span> {{
                 keyword[date].place < 101 ? keyword[date].place : "-"
               }}
              <sup v-if="keyword[date].change === '+'"><i class="bi bi-arrow-up text-success"></i></sup>
              <sup v-else-if="keyword[date].change === false"><i class="bi bi-x text-danger"></i></sup>
              <sup v-else-if="keyword[date].change"
                   :class="`text-${keyword[date].change > 0?'success':'danger'}`"><span v-if=" keyword[date].change >0">+</span>{{
                  keyword[date].change
                }}</sup>
               </span>

            </td>
          </template>
        </tr>
        </tbody>
      </table>

    </div>


  </main>


</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from "moment"
import {DomainApi} from "@/api/domains";
import {PictureApi} from "@/api/pictures";
import {Bar, Doughnut} from "vue-chartjs";

import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: 'DomainDetail',
  components: {Bar, Doughnut},

  data() {
    return {
      date_types: {
        two: "Две даты",
        period: "Период",
        free: "Произвольные даты",
      },
      color_types: {
        top: "Топ, прогресс",
        change: "Рост/Падение",
      },
      color_type: "top",
      key_q: "",
      date_type: "two",
      domain: null,
      copy: false,
      dates: [],
      order: "",
      order_direction: 0,
      positions: [],
      project: null,
      engine: 'yandex',
      active_country: {},
      keywords: [],
      allowedDates: [],
      htmlLegendPlugin: htmlLegendPlugin,
      choice_period_table: [1, 3, '#3780FF'],
      period_params: [
        [101, "", '#FCC53B'],
        [51, 100, '#BCC8C8'],
        [21, 50, '#94DA90'],
        [11, 20, '#1ABC9C'],
        [4, 10, '#21936C'],
        [1, 3, '#3198DD']
      ]
    }
  },
  watch: {

    active_country: function (val, prev) {
      if (prev.code) this.getData()
    },
    dates: function (val, prev) {
      if (prev.length) this.getData()
    },
  },
  mounted: function () {
    const $this = this;
    DomainApi.get($this.$route.params.domain_id).then(r => this.domain = r)
    ProjectApi.get_simple($this.$route.params.id).then(pr => {
      $this.project = pr;
      $this.active_country = $this.project.countries[0]
      ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => {
        $this.allowedDates = response;
        $this.dates = $this.allowedDates.sort().reverse().slice(0, 2).reverse();
        $this.getData();
      })
    })

  },
  computed: {
    chartOptions() {

      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                stacked: true,
                grid: {
                  display: false
                }
              },
          y:
              {
                stacked: true,
                grid: {
                  display: false
                },
                ticks: {
                  display: false,
                },
              }
        },
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          },
          datalabels: {
            formatter: function (value, context) {
              return !!value?value:'';
            }
          }
        }
      }
    },
    table_positions() {
      let datas = [], position = this.positions.filter(x => x.result__engine__code === this.engine);
      const $this = this;
      this.keywords.filter(x => x.name.toLowerCase().includes(this.key_q)).forEach((key, k_index) => {
        let keyword = {
          name: key.name,
          id: key.id
        }
        // let dates = JSON.parse(JSON.stringify($this.table_dates)).reverse();
        let dates = $this.table_dates;
        dates.forEach((date, index) => {
          let pos = position.find(x => x.date_create__date === date && x.result__keyword_id === key.id)
          keyword[date] = {place: pos?.place || 101, change: null}
          if (index) {
            let prev = keyword[dates[index - 1]].place;
            if (!pos && prev) keyword[date].change = false;
            else if (pos && !prev) keyword[date].change = '+';
            else keyword[date].change = (prev === 101) ? 0 : prev - keyword[date].place

          }
        })
        datas.push(keyword)
      })
      if (this.order) datas = datas.sort((a, b) => {
        if (!$this.order_direction) {
          return a[$this.order].place - b[$this.order].place
        } else return b[$this.order].place - a[$this.order].place
      })
      return datas
    },
    data_doughnut() {
      let $this = this,
          data = [], labels = [];
      this.period_params.forEach(x => {
        let label = x[0] + "-" + x[1]
        labels.push(label);
        data.push(this.datacollection.datasets.find(l => l.label === label).data.reduce((a, c) => {
          return a + c
        }, 0))
      })
      return {
        labels: labels, datasets: [{
          backgroundColor: $this.period_params.map(x => x[2]),
          hoverBackgroundColor: $this.period_params.map(x => x[2] + "99"),
          data: data
        }]
      }
    },
    datacollection() {
      let $this = this,
          dataset = [], all_count = $this.keywords.length;
      let dates = JSON.parse(JSON.stringify($this.table_dates)).reverse()
      this.period_params.forEach(function (param) {
        let param_data = {
          label: `${param[0]}-${param[1]}`,
          hoverBackgroundColor: `${param[2]}99`,
          backgroundColor: `${param[2]}`,
          data: []
        }
        if ($this.table_positions.length) dates.forEach(function (el) {
          let all_l;
          if (param[0] === 101) {
            all_l = $this.keywords.filter(key => $this.table_positions?.find(x => x.id === key.id)[el]?.place === 101).length
          } else {
            all_l = $this.table_positions.map(x => x[el].place).filter(x => {
              return x && param[0] <= x && x <= param[1]
            }).length
          }
          param_data.data.push(Math.round(all_l / (all_count / 100)))
        })
        dataset.push(param_data)
      })
      return {labels: dates, datasets: dataset}
    },
    table_dates() {
      let dates = [];
      if (this.date_type === "period") {
        let end = moment(this.dates[1])
        let current = moment(this.dates[0])
        while (current.isBefore(end)) {
          dates.push(current.format('YYYY-MM-DD'));
          current.add(1, 'days');
        }
        dates.push(this.dates[1])
      } else dates = this.dates;
      return dates
    },
    stat_positions() {
      let dates = this.table_dates, result = [];
      if (dates.length) {
        let date_end = dates[0], date_start = dates[dates.length - 1]
        this.period_params.forEach((param) => {
          let data = this.table_positions.filter(x => {
            return (param[1] ? param[1] : 102) >= x[date_start].place && x[date_start].place >= param[0]
          });
          console.log(date_end)
          let total = data.map(x => (x[date_end].place < 101 && x[date_start].place < 101) ? x[date_end].place - x[date_start].place : 0).reduce((a, c) => {
            return a + c
          }, 0)
          result.push({
            name: param,
            percent: Math.round(data.length * (100 / this.table_positions.length)) + '%',
            len: data.length,
            change: total
          })
        })
      }
      return result
    },
    stat_medium() {
      let result = {};
      if (this.table_dates.length && this.table_positions.length) {
        let date = this.table_dates[this.table_dates.length - 1]
        let up = this.table_positions.filter(x => x[date].change > 0)
        let no_change = this.table_positions.filter(x => x[date].change === 0)
        let down = this.table_positions.filter(x => x[date].change < 0)
        let all = this.table_positions.length;
        result.up = {count: up.length, percent: Math.round(up.length * (100 / all))}
        result.no_change = {count: no_change.length, percent: Math.round(no_change.length * (100 / all))}
        result.down = {count: down.length, percent: Math.round(down.length * (100 / all))}
        result.donut = {
          labels: [], datasets: [{
            backgroundColor: ["#36b556", "#f48b1b", "#f12e27"],
            data: [result.up.count, result.no_change.count, result.down.count]
          }]
        }

      }
      return result
    },
    engines() {

      // if (this.project && this.project.countries && this.project_country) {
      //   let country = false;
      //   if (this.graph === 'keyword') {
      //     country = this.project.countries.filter(x => x.code === this.project_country.code)[0];
      //   } else {
      //     country = this.project.countries.filter(x => x.code === this.active_country.code)[0];
      //   }
      //   if (country) {
      //     let engines = [];
      //     if (country.yandex) engines.push('yandex')
      //     if (country.google) engines.push('google')
      //     if (!(engines.indexOf(this.engine) + 1)) this.engine = engines[0]
      //     return engines
      //   }
      // }
      // }
      return ['yandex', 'google']
    }

  },
  methods: {
    copyTable() {
      const $this = this;
      this.copy = true;
      setTimeout(() => {
        const text = document.getElementById("statTable").innerText.trim();
        navigator.clipboard.writeText(text);
        $this.copy = false;
        $this.$notify({
          type: 'success ',
          text: 'Скопировано в буфер обмена'
        });
      }, 500)

    },
    getParamTable(date) {
      let data = this.table_positions.map(x => x[date].place)

      let count = data.filter(x => (this.choice_period_table[1] ? this.choice_period_table[1] : 102) >= x && x >= this.choice_period_table[0]).length
      return parseInt(count * (100 / data.length))
    },
    setOrder(date) {
      if (this.order === date && this.order_direction === 1) {
        this.order_direction = 0;
        this.order = "";
      } else if (this.order === date && !this.order.direction) this.order_direction = 1;
      else {
        this.order = date;
        this.order_direction = 0;
      }
    },
    getClass(position) {
      let name;
      if (this.color_type === "top") {
        if (position.place > 100) name = "warning"
        else if (position.place < 4) name = "primary"
        else if (position.place < 11) name = "success"
        else if (position.place) name = "success"
      } else {
        if (position.change > 0) name = "success"
        else if (position.place > 100) name = ""
        else if (position.change === '+') name = "success"
        else if (position.change < 0) name = "danger"
        else if (position.place < 6) name = "primary"
      }
      return "bg-place-" + name
    },
    getData() {
      const $this = this;
      let filters = {
        country_code: this.active_country.code,
        dates: this.table_dates
      }

      let f_string = new URLSearchParams(filters).toString();
      PictureApi.keywords(this.$route.params.id, this.active_country.code).then(r => {
        $this.keywords = r;
        DomainApi.get_stat_data($this.$route.params.domain_id, f_string).then(resp => {
          $this.positions = resp
        });
      });
    },


    getReport: function () {
      let $this = this;
      let filters = {
        country_code: this.active_country.code,
        dates: this.table_dates
      }
      let f_string = new URLSearchParams(filters).toString();
      DomainApi.get_report_data($this.$route.params.domain_id, f_string).then(() => {
        $this.$notify({
          type: 'success ',
          text: 'Процесс создания отчёта запущен. Отчёт будет доступен в разде "Отчёты"'
        });
      })


    },

  }
}

</script>
<style scoped lang="scss">
.filters {
  display: flex;
  gap: 20px;

  .dropdown {
    button, .dropdown-item {
      gap: 5px;
      display: flex;
      align-items: center;
      height: 35px;
    }
  }
}

table, th, td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 5px;
}

table {
  td {
    text-align: center;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bg-place-warning {
  background-color: #fff6c6;
}

.bg-place-primary {
  background-color: #cce0ff;
}

.bg-place-success {
  background-color: #b6fbd5;
}

.bg-place-danger {
  background-color: #fff1f0;

}

th span {
  cursor: pointer;
  padding: 5px;
}

.active-sort {
  background: #72ff88;
  border-radius: 10px;
}

.danger-class {
  background: #f04124;
  border-radius: 10px;
}

.period-params-table {
  button {
    border: none;
    color: #999999;
    font-size: 12px;
    padding: 0;
    margin-top: 7px;
    margin-bottom: 0;
  }

  .percent {
    font-size: 12px;
    font-weight: normal;
    color: #999;
  }
}

.stats {
  background: #eee;
  padding: 10px;
  display: flex;
  gap: 20px;

  .graph {
    width: 100px;
    height: 120px;
    padding: 10px;
    box-shadow: 0 0 13.6px 4px #00000012;
    background: #fff;
    border-radius: 5px;
  }

  .stat {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    box-shadow: 0 0 13.6px 4px #00000012;
    background: #fff;
    border-radius: 5px;
    justify-content: center;

    .place {
      display: flex;
      gap: 30px;
      border-bottom: 1px solid #ccc;
      color: #8491a4;
      padding-bottom: 3px;
      align-items: baseline;

      span {
        display: flex;
        gap: 5px;

        b {
          display: block;
          height: 20px;
          width: 20px;
          background: #ccc;
          color: #fff1f0;
          text-align: center;
          border-radius: 20px;
          line-height: 23px;
        }
      }

      &.up {
        span {
          color: #36b556;

          b {
            background: #36b556;
          }
        }
      }

      &.no-change {
        span {
          color: #f48b1b;

          b {
            background: #f48b1b;
          }
        }
      }

      &.down {
        span {
          color: #f12e27;

          b {
            background: #f12e27;
          }
        }
      }

    }
  }

  .props {
    display: flex;
    gap: 10px;

    .prop {
      padding: 5px;
      box-shadow: 0 0 13.6px 4px #00000012;
      background: #fff;
      border-radius: 5px;
      width: 65px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .name {
        text-align: center;
        color: #fff1f0;
        font-weight: bold;
        border-radius: 5px;
        padding: 3px;
        font-size: 13px;

      }

      .data {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;

        span {
          display: block;
          width: 100%;
          text-align: center;

          &:first-child {
            color: #999;
            border-bottom: 1px solid #ccc;

          }
        }

      }
    }
  }
}

</style>
