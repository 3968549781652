<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="settings">
          <label for="boardSize">Размер поля:</label>
          <input id="boardSize" type="range" value="10" min="4" max="20">
          <fieldset>
            <input type="radio" id="easy" name="difficulty" value="0.1" class="difficulty">
            <label for="easy">Легко</label>
            <input type="radio" id="normal" name="difficulty" value="0.2" class="difficulty" checked>
            <label for="normal">Нормально</label>
            <input type="radio" id="hard" name="difficulty" value="0.4" class="difficulty">
            <label for="hard">Сложно</label>
          </fieldset>
          <a href="#!" class="minesweeper-btn btn btn-dark">Новая игра</a>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="board-wrap">
        <div class="board"></div>
      </div>
      <div class="endscreen"></div>

    </div>
  </div>
</template>

<script>


export default {
  name: 'Sapper',
  props: {},
  mounted() {
    let size = 10;
    let bombFrequency = 0.2;
    let tileSize = 50;

    const board = document.querySelectorAll('.board')[0];
    let tiles;
    let boardSize;

    const restartBtn = document.querySelectorAll('.minesweeper-btn')[0];
    const endscreen = document.querySelectorAll('.endscreen')[0]

    const boardSizeBtn = document.getElementById('boardSize');
    const difficultyBtns = document.querySelectorAll('.difficulty');

    let bombs = [];
    let numbers = [];
    let numberColors = ['#3498db', '#2ecc71', '#e74c3c', '#9b59b6', '#f1c40f', '#1abc9c', '#34495e', '#7f8c8d',];
    let endscreenContent = {win: '<span>✔ Вы выиграли!</span>', loose: '💣 БУМ! Конец игры.'};

    let gameOver = false;

    const clear = () => {

      gameOver = false;
      bombs = [];
      numbers = [];
      endscreen.innerHTML = '';
      endscreen.classList.remove('show');
      tiles.forEach(tile => {
        tile.remove();
      });

      setup();
    }

    const setup = () => {
      for (let i = 0; i < Math.pow(size, 2); i++) {
        const tile = document.createElement('div');
        tile.classList.add('tile');
        board.appendChild(tile);
      }
      tiles = document.querySelectorAll('.tile');
      boardSize = Math.sqrt(tiles.length);
      board.style.width = boardSize * tileSize + 'px';

      document.documentElement.style.setProperty('--tileSize', `${tileSize}px`);
      document.documentElement.style.setProperty('--boardSize', `${boardSize * tileSize}px`);

      let x = 0;
      let y = 0;
      tiles.forEach((tile, i) => {
        tile.setAttribute('data-tile', `${x},${y}`);

        let random_boolean = Math.random() < bombFrequency;
        if (random_boolean) {
          bombs.push(`${x},${y}`);
          if (x > 0) numbers.push(`${x - 1},${y}`);
          if (x < boardSize - 1) numbers.push(`${x + 1},${y}`);
          if (y > 0) numbers.push(`${x},${y - 1}`);
          if (y < boardSize - 1) numbers.push(`${x},${y + 1}`);

          if (x > 0 && y > 0) numbers.push(`${x - 1},${y - 1}`);
          if (x < boardSize - 1 && y < boardSize - 1) numbers.push(`${x + 1},${y + 1}`);

          if (y > 0 && x < boardSize - 1) numbers.push(`${x + 1},${y - 1}`);
          if (x > 0 && y < boardSize - 1) numbers.push(`${x - 1},${y + 1}`);
        }

        x++;
        if (x >= boardSize) {
          x = 0;
          y++;
        }

        tile.oncontextmenu = function (e) {
          e.preventDefault();
          flag(tile);
        }

        tile.addEventListener('click', function (e) {
          clickTile(tile);
        });
      });

      numbers.forEach(num => {
        let coords = num.split(',');
        let tile = document.querySelectorAll(`[data-tile="${parseInt(coords[0])},${parseInt(coords[1])}"]`)[0];
        let dataNum = parseInt(tile.getAttribute('data-num'));
        if (!dataNum) dataNum = 0;
        tile.setAttribute('data-num', dataNum + 1);
      });
    }


    const flag = (tile) => {
      if (gameOver) return;
      if (!tile.classList.contains('tile--checked')) {
        if (!tile.classList.contains('tile--flagged')) {
          tile.innerHTML = '🚩';
          tile.classList.add('tile--flagged');
        } else {
          tile.innerHTML = '';
          tile.classList.remove('tile--flagged');
        }
      }
    }

    const clickTile = (tile) => {
      if (gameOver) return;
      if (tile.classList.contains('tile--checked') || tile.classList.contains('tile--flagged')) return;
      let coordinate = tile.getAttribute('data-tile');
      if (bombs.includes(coordinate)) {
        endGame(tile);
      } else {

        let num = tile.getAttribute('data-num');
        if (num != null) {
          tile.classList.add('tile--checked');
          tile.innerHTML = num;
          tile.style.color = numberColors[num - 1];
          setTimeout(() => {
            checkVictory();
          }, 100);
          return;
        }

        checkTile(tile, coordinate);
      }
      tile.classList.add('tile--checked');
    }


    const checkTile = (tile, coordinate) => {
      let coords = coordinate.split(',');
      let x = parseInt(coords[0]);
      let y = parseInt(coords[1]);

      setTimeout(() => {
        if (x > 0) {
          let targetW = document.querySelectorAll(`[data-tile="${x - 1},${y}"`)[0];
          clickTile(targetW, `${x - 1},${y}`);
        }
        if (x < boardSize - 1) {
          let targetE = document.querySelectorAll(`[data-tile="${x + 1},${y}"`)[0];
          clickTile(targetE, `${x + 1},${y}`);
        }
        if (y > 0) {
          let targetN = document.querySelectorAll(`[data-tile="${x},${y - 1}"]`)[0];
          clickTile(targetN, `${x},${y - 1}`);
        }
        if (y < boardSize - 1) {
          let targetS = document.querySelectorAll(`[data-tile="${x},${y + 1}"]`)[0];
          clickTile(targetS, `${x},${y + 1}`);
        }

        if (x > 0 && y > 0) {
          let targetNW = document.querySelectorAll(`[data-tile="${x - 1},${y - 1}"`)[0];
          clickTile(targetNW, `${x - 1},${y - 1}`);
        }
        if (x < boardSize - 1 && y < boardSize - 1) {
          let targetSE = document.querySelectorAll(`[data-tile="${x + 1},${y + 1}"`)[0];
          clickTile(targetSE, `${x + 1},${y + 1}`);
        }

        if (y > 0 && x < boardSize - 1) {
          let targetNE = document.querySelectorAll(`[data-tile="${x + 1},${y - 1}"]`)[0];
          clickTile(targetNE, `${x + 1},${y - 1}`);
        }
        if (x > 0 && y < boardSize - 1) {
          let targetSW = document.querySelectorAll(`[data-tile="${x - 1},${y + 1}"`)[0];
          clickTile(targetSW, `${x - 1},${y + 1}`);
        }
      }, 10);
    }

    const endGame = (tile) => {
      endscreen.innerHTML = endscreenContent.loose;
      endscreen.classList.add('show');
      gameOver = true;
      tiles.forEach(tile => {
        let coordinate = tile.getAttribute('data-tile');
        if (bombs.includes(coordinate)) {
          tile.classList.remove('tile--flagged');
          tile.classList.add('tile--checked', 'tile--bomb');
          tile.innerHTML = '💣';
        }
      });
    }

    const checkVictory = () => {
      let win = true;
      tiles.forEach(tile => {
        let coordinate = tile.getAttribute('data-tile');
        if (!tile.classList.contains('tile--checked') && !bombs.includes(coordinate)) win = false;
      });
      if (win) {
        endscreen.innerHTML = endscreenContent.win;
        endscreen.classList.add('show');
        gameOver = true;
      }
    }
    setup();

    restartBtn.addEventListener('click', function (e) {
      e.preventDefault();
      clear();
    });

    boardSizeBtn.addEventListener('change', function (e) {
      size = this.value;
      tileSize = 70 - (size * 2);
      clear();
    });
    difficultyBtns.forEach(btn => {
      btn.addEventListener('click', function () {
        bombFrequency = this.value;
        clear();
      });
    });
  }
}
</script>
<style>
:root {
  --tileSize: 50px;
  --boardSize: 0px;
}

.endscreen {
  background: #FFF;
  padding: 20px;
  text-align: center;
  font-size: 40px;
  display: none;
  color: red;
  font-family: 'Roboto Condensed', sans-serif;
}

.endscreen.show {
  display: block;
}

.endscreen span {
  color: green;
}

.col-left {
  display: inline-block;
  width: 48%;
}

.col-right {
  display: inline-block;
  width: 48%;
  text-align: right;
}

.board {
  background: #e2e2e2;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px auto;
  border: 8px solid #000;
  box-sizing: content-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 16px 20px rgba(0, 0, 0, 0.2);
}

.board .tile {
  background: #e2e2e2;
  width: var(--tileSize);
  height: var(--tileSize);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--tileSize) * .8);
  cursor: pointer;
  user-select: none;
  transition: background .2s ease;
  font-family: Tahoma, sans-serif;
  box-shadow: inset 0 2px 0 0 #fcfcfc, inset 2px 0 0 0 #fcfcfc, inset 0 -2px 0 0 #c9c9c9, inset -2px 0 0 0 #c9c9c9;
}

.board .tile:hover:not(.tile--checked) {
  background: #efefef;
}

.board .tile--checked {
  background: #d5d5d5;
  box-shadow: inset 0 1px 0 0 #c9c9c9, inset 1px 0 0 0 #c9c9c9, inset 0 -1px 0 0 #c9c9c9, inset -1px 0 0 0 #c9c9c9;
}

.board .tile--checked.tile--bomb:before, .board .tile--checked.tile--bomb:after {
  opacity: 1;
}

.board .tile--checked:hover {
  cursor: default;
}

.board .tile--bomb {
  font-size: calc(var(--tileSize) * .5);
}

.board .tile--flagged {
  font-size: calc(var(--tileSize) * .5);
}

.settings {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 auto;
}

.settings fieldset {
  border: none;
}

.settings input[type=radio] {
  display: none;
}

.settings label {
  margin: 12px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  transition: .4s all;
  font-weight: bold !important;
}

.settings input[type=radio] + label {
  margin: 12px 14px 12px 22px;
}

.settings input[type=radio] + label:hover {
  color: #000;
}

.settings input[type=radio] + label:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  left: -24px;
  border: 2px solid #000;
  border-radius: 2px;
  transition: background .4s;
}

.settings input[type=radio] + label:before {
  border-radius: 100%;
}

.settings input[type=radio]:checked + label:before {
  background: #000;
}

@media screen and (max-width: 767px) {
  .board-wrap {
    transform: scale(0.8);
    width: calc(100% + 160px);
    left: -80px;
    position: relative;
  }
}

@media screen and (max-width: 520px) {
  .board-wrap {
    transform: scale(0.5);
    width: calc(100% + 360px);
    left: -180px;
  }
}
</style>
