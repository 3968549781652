<template>
  <div :class="`code-area detail`">
    <div class="head">
      <h3>
        <span>{{ code.issuer }} </span>
        <small> {{ code.name }} </small>
        <small class="login" v-if="code.login">({{ code.login }})</small>
<router-link :to="{name: 'authy'}" class="btn btn-sm btn-outline-dark float-end">К списку</router-link>
      </h3>
      <div class="btn-groups d-flex" v-if="code">
        <button @click.prevent="$modal('show', 'code_modal-' + $route.params.code_id);"
                class="btn btn-sm btn-outline-primary">
          Редактировать
        </button>
        <button @click.prevent="deleteCode" class="btn btn-sm btn-outline-danger">Удалить</button>
        <button class="btn btn-sm btn-outline-dark" @click="createQrCode">Получить QR</button>
        <button class="btn btn-sm btn-outline-dark" @click.prevent="addToShare($route.params.code_id)"><i
            class="bi bi-share"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="body col">
        <div class="timer">
          <svg width="250" height="150" viewBox="0 0 250 250" class="timer-progress">
            <circle class="bg"></circle>
            <circle class="fg"></circle>
          </svg>
          <span>{{ timer }}</span>
          <button @click="copyCode" class="btn btn-sm">{{ code.now }}</button>
        </div>
      </div>
      <div class="col" style="text-align: center" v-if="qr_link">
        <qrcode-vue :size="250" :value="qr_link"></qrcode-vue>
      </div>
    </div>

    <div class="groups">
      <div class="group" v-for="group in code.groups">
        {{ group.name }}
        <a href="" @click.prevent="removeGroup(group)"> <i class="bi bi-x"></i></a>
      </div>
      <form @submit.prevent="createGroup" class="autocomplete">
        <input class="form-control" placeholder="Добавить группу" type="text"
               v-model="searchTerm">
        <ul v-if="search.length">
          <li
              v-for="group in search"
              :key="group.name"
          >
            <a href="" @click.prevent="addGroup(group)"> {{ group.name }}</a>
          </li>
        </ul>
      </form>

    </div>


    <div class="detail-info">
      <p><b>{{ code.name }}</b></p>
      <p v-if="code.issuer"><b>Эмитент:</b>{{ code.issuer }}</p>
      <p><b>Описание:</b>{{ code.description }}</p>
      <p><b>Код:</b> <span @click="show_secret=!show_secret" :class="show_secret?'':'mute'">{{ code.secret }}</span></p>
      <p><b>Количество цифр:</b> {{ code.digits }}</p>

      <p v-if="code.initial_count"><b>HTOP:</b> {{ code.initial_count }}</p>
      <p v-else><b>TOPT:</b> {{ code.interval }}</p>
      <p><b>Логин:</b> {{ code.login }}</p>
      <p><b>Пароль:</b> <span @click="show_pass=!show_pass" :class="show_pass?'':'mute'">{{ code.pwd }}</span></p>
    </div>
    <modal v-if="code" :modal_id="'code_modal-'+$route.params.code_id">
      <template v-slot:header>
        <h2>Редактирование</h2>
      </template>

      <template v-slot:body>
        <div class="buttons">
          <form action="" @submit.prevent="createCode" class="manual">
            <label>Название
              <input type="text" placeholder="Название" class="form-control" v-model="code.name">
            </label>
            <label>Эмитент
              <input type="text" placeholder="Эмитент" class="form-control" v-model="code.issuer">
            </label>
            <label>Количество цифр
              <input type="number" placeholder="Количество цифр" class="form-control" v-model="code.digits">
            </label>
            <label v-if="code.initial_count">HTOP
              <input type="number" placeholder="Начальное значение счетчика" class="form-control"
                     v-model="code.initial_count">
            </label>
            <label v-if="code.interval">TOPT
              <input type="number" placeholder="Интервал" class="form-control" v-model="code.interval">
            </label>

            <label>Логин
              <input type="text" placeholder="Логин" class="form-control" v-model="code.login">
            </label>
            <label>Пароль
              <div class="password">
                <input class="form-control" :type="show_pass?'text':'password'" name="password"
                       v-model="code.pwd" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :style="{color: show_pass?'#000':'#ccc'}"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
              </div>
            </label>
            <label>Код
              <div class="password">
                <input class="form-control" :type="show_secret?'text':'password'" name="secret"
                       v-model="code.secret" placeholder="Код">
                <i v-on:click="show_secret=!show_secret" :style="{color: show_secret?'#000':'#ccc'}"
                   :class="`bi bi-eye${show_secret?' active':''}`"></i>
              </div>
            </label>
            <label>Описание
              <textarea cols="30" rows="5" v-model="code.description" placeholder="Описание"></textarea>
            </label>
            <button class="btn btn-sm btn-primary" type="submit">Сохранить</button>
          </form>
        </div>

      </template>

      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'code_modal-'+code.id);"
                data-bs-dismiss="modal">Отмена
        </button>
      </template>
    </modal>
  </div>

</template>

<script>
import {AuthyApi} from "@/authy/api/authy";
import QrcodeVue from 'qrcode.vue'
import {state, wsClient} from "@/socket";

export default {
  name: 'AuthyDetail',
  components: {QrcodeVue},

  data() {
    return {
      code: {},
      authySocket: null,
      timer: 0,
      searchTerm: "",
      show_pass: false,
      show_secret: false,
      timer_start: 0,
      timer_interval: 1,
      intervalId: null,
      groups: {},
      qr_link: null

    }
  },

  mounted() {
    this.authySocket = wsClient
    this.authySocket.connectWs('authy');
    this.getCode(this.$route.params.code_id)
    AuthyApi.groups().then(r => this.groups = r.results)
    // this.getTimer()
    setInterval(() => this.timer_start += this.timer_interval, 100);
  },
  computed: {
    state_authy(value) {
      return state.authy
    },
    width() {
      return this.code?.groups?.map(x => x.name.length * 5 + 45).reduce((a, x) => a + x, 0) + 15;
    },
    search() {
      const obj_groups = this.code?.groups?.map(x => x.id) || []
      if (this.searchTerm.length < 1) {
        return []
      }
      return this.groups.filter(group => {
        return !obj_groups.includes(group.id) && group.name.toLowerCase()
            .startsWith(this.searchTerm.toLowerCase())
      })
    },
  },
  watch: {
    state_authy(value) {
      console.log(value)
      if (value.id === this.$route.params.code_id) this.code = value
    },
    timer(value) {
      if (value <= 0) this.getDetail();
    },
    "code.now"(val) {
      console.log(val)
      this.getTimer()
    }
  },

  methods: {
    createQrCode(){
      this.qr_link = "otpauth://totp/"+this.code.login+"?secret="+this.code.secret+(this.code.issuer?"&issuer="+this.code.issuer:"")
    },
    getCode(id) {
      this.authySocket.sendWs(JSON.stringify({code_id: id}));
    },
    addToShare() {
      AuthyApi.create_share({ids: [this.$route.code_id]}).then(response => {
            const url = window.location.origin + '/authy/' + "?token=" + response.link;
            try {
              navigator.clipboard.writeText(url);
              this.$notify({
                type: 'success',
                text: "Ссылка скопирована в буфер обмена"
              });
            } catch ($e) {
              alert("Копирование не доступно. Ссылка для шаринга кодов " + url)
            }

          }
      )
    },

    getTimer() {
      this.timer = Math.abs(this.code.interval - parseInt(Math.floor(+new Date().getTime() / 1000 % this.code.interval)));
      this.timer_start = 100 - parseFloat(this.timer / this.code.interval * 100)
      this.timer_interval = parseFloat(10 / this.code.interval)
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.timer -= 1;
        if (this.timer < 0) this.timer = 0
      }, 1000);
    },
    getDetail() {
      this.getCode(this.code.id)
    },
    createCode() {
      let code = JSON.parse(JSON.stringify(this.code));
      delete code.users;
      delete code.groups;
      delete code.now;
      AuthyApi.patch(this.code.id, code).then(() => this.$modal('hide', 'code_modal-' + this.code.id));
    },
    deleteCode() {
      AuthyApi.delete(this.code.id).then(() => this.removeCode(this.code.id))
    },
    updateGroup() {
      AuthyApi.patch(this.code.id, {groups: this.code.groups.map(x => x.id)})
    },
    removeGroup(group) {
      this.code.groups.splice(this.code.groups.findIndex(x => x.id === group.id), 1);
      this.updateGroup()
    },
    addGroup(group) {
      this.code.groups.push(group)
      this.searchTerm = "";
      this.updateGroup()
    },
    createGroup() {
      if (this.searchTerm.length) {
        const $this = this;
        AuthyApi.add_groups({name: this.searchTerm}).then(response => {
          $this.groups.push(response);
          $this.addGroup(response)
        })
      }

    },
    copyCode() {
      try {
        navigator.clipboard.writeText(this.code.now);
        this.$notify({
          type: 'success',
          text: "Добавленно в буфер обмена"
        });
      } catch ($e) {
        this.$notify({
          type: 'error',
          text: "Копирование не доступно"
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mute {
  background: #ccc;
  color: #ccc;
  cursor: pointer;
}

.code-area.detail {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 8.8px 0 rgba(0, 0, 0, .27);

  .head {
    .btn-groups {
      gap: 10px;
    }
  }

  .body {
    display: flex;
    align-items: center;

    button {
      font-family: "Arial";
      letter-spacing: 2px;
      font-size: 30px;
      font-weight: bold;
      color: rgba(55, 128, 255, 1);
      cursor: pointer;
      z-index: 10;
    }

    .timer {
      position: relative;
      margin: 50px;

      .timer-progress {
        --size: 250px;
        --timer: 30s;
        --half-size: calc(var(--size) / 2);
        --stroke-width: 5px;
        --radius: calc((var(--size) - var(--stroke-width)) / 2);
        --circumference: calc(var(--radius) * pi * 2);
        --dash: calc((v-bind("timer_start") * var(--circumference)) / 100);
        //animation: progress-animation var(--timer) linear 0s 1 forwards;
        //animation-iteration-count: infinite;


        circle {
          cx: var(--half-size);
          cy: var(--half-size);
          r: var(--radius);
          stroke-width: var(--stroke-width);
          fill: none;
          stroke-linecap: round;

          &.bg {
            stroke: #ddd;
          }

          &.fg {
            transform: rotate(-90deg);
            transform-origin: var(--half-size) var(--half-size);
            stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
            transition: stroke-dasharray 0.3s linear 0s;
            stroke: #5394fd;
          }
        }
      }

      span {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-30%, -50%);
        font-weight: bold;
      }

      button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
      }
    }
  }
}

form.manual {
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  border: 1px solid;
  border-radius: 20px;

  ::placeholder {
    color: rgba(139, 139, 139, 1);
  }

  label {

    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    align-items: self-start;

    input, textarea, .password {
      width: 100%;
    }
  }
}

.autocomplete {
  z-index: 1;

  ul {
    position: absolute;
    border: 1px solid #ccc;
    list-style: none;
    padding: 10px;
    font-size: 15px;
    font-weight: normal;
    background: #fff;
    width: 250px;
    max-height: 160px;
    overflow-y: auto;

    a {
      width: 100%;
      display: block;

      &:hover {
        background: #ccc;
      }

    }
  }

  input {
    width: 150px;
    border-color: #ccc;
    box-shadow: none;
    border-radius: 10px;
    height: 33px;

    &::placeholder {
      color: rgba(139, 139, 139, 1);
    }
  }
}

.groups {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: auto;
  position: relative;

  .group {
    border: 1px solid rgba(139, 139, 139, 1);
    border-radius: 10px;
    font-size: 13px;
    font-weight: normal;
    padding: 5px;
    z-index: 2;
    background: rgba(139, 139, 139, 1);
    color: #fff;
    height: 28px;
    line-height: 10px;
    margin-left: 3px;
  }

  button {
    display: flex;
    align-self: center;
  }
}


</style>

