<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import {wsClient} from "@/socket";
export default {
  name: 'UniqueText',
  mounted() {
    const textWs = wsClient;
    textWs.base_url = `${location.protocol.includes("https")?"wss":"ws"}://${process.env.VUE_APP_TEXT_API}/ws/`;
    textWs.connectWs('response_channel');
  }
}

</script>
<style scoped>
.dashboard {
  margin-left: 26%;
  width: 75%;
  margin-top: 100px;
}

</style>
