<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Проект <span>{{ project?.name }}</span></h2>
      <h3>Персона {{ person?.name }} ID ({{ person?.pf_id }})</h3>
      <BackButton></BackButton>
    </div>
    <div class="filters mb-5">
      <button class="btn-outline-success btn btn-sm" @click="add_new=true">Добавить +</button>
    </div>

    <div class="screen-list">


      <div class="header">

      </div>
      <div v-if="!pdf && add_new">
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
        <template v-for="(name, key) in sources">
          <input type="radio" class="btn-check" name="source" :id="'source'+key" autocomplete="off" :value="key"
                 v-model="source">
          <label class="btn btn-outline-dark" :for="'source'+key">{{ name }}</label>
        </template>

      </div>
        <div
            :class="['dropzone-area', dragging ? 'dropzone-over' : '']"
            drag-over="handleDragOver"
            @dragenter="dragging=true"
            @dragleave="dragging=false">

          <div class="dropzone-text">
            <span class="dropzone-title">Перетащите файл сюда или кликните для выбора</span>
            <span class="dropzone-info">PDF file</span>
          </div>
          <input type="file" accept="application/pdf" @change="onFileChange">
        </div>
      </div>
      <div class="dropzone-preview">
        {{ pdf.name }}
        <button @click="removeFile" class="btn btn-sm btn-outline-dark" v-if="pdf">Загрузить другой</button>
      </div>

      <div class="table" v-if="structure">
        <h1>{{ structure.name }}</h1>
        <div v-for="data in structure">
          <h1>{{ data.name }}</h1>
          <div v-for="array in data.arrays" class="d-flex" style="gap: 10px; border-bottom: 1px solid #ccc">
            <div v-for="d in array" style="min-width: 100px;
    padding: 5px;">
              <p v-if="d.text">
                <a v-if="d.link" target="_blank" class="text-primary" :href="d.link">{{ d.text }}</a>
                <span v-else>{{ d.text }}</span>

              </p>
              <img v-else :src="d.img" alt="" style="max-width: 150px">
            </div>
          </div>
        </div>

      </div>
<div class="table-responsive">
      <div class="table">
        <table>
          <tbody>
          <tr>
            <td><a href="#">DJ</a></td>
            <td>pdf</td>
            <td>14.02.2025</td>
            <td>
              <button class="btn btn-sm btn-outline-danger rounded">x</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      </div>

    </div>

  </main>
</template>

<script>

import {PdfApi} from "@/api/pdf";
import {ProjectApi} from "@/api/project";

export default {
  name: 'PdfDB',
  data() {
    return {
      pdf: '',
      dragging: false,
      structure: null,
      sources: [],
      source: "",
      person: null,
      project: null,
      add_new: false
    }
  },
  mounted: function () {
    const $this = this;
    PdfApi.project_info(this.$route.params.id).then(response => {
      $this.sources = response.sources;
      $this.source = Object.keys($this.sources)[0]
      $this.person = response.person;
    })
    ProjectApi.get_simple(this.$route.params.id).then(response => {
      $this.project = response
    });

  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.parserPdf(files[0]);
    },
    parserPdf(file) {
      this.pdf = file;
      let formData = new FormData()
      formData.set('file', this.pdf)
      PdfApi.load_pdf(formData).then((response) => {
        this.structure = response
      });
    },
    removeFile() {
      this.pdf = '';
    }
  }
}

</script>

<style scoped lang="scss">
.dropzone-over {

  border: 1px solid red !important;
}

.dropzone-area {
  width: 80%;
  height: 150px;
  position: relative;
  border: 2px dashed #CBCBCB;

  &:hover {
    border: 2px dashed #2E94C4;

    .dropzone-title {
      color: #1975A0;
    }

  }
}

.dropzone-area input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropzone-text {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 100%;

  span {
    display: block;
    line-height: 1.9;
  }
}

.dropzone-title {
  font-size: 13px;
  color: #787878;
  letter-spacing: 0.4px;
}

.dropzone-info {
  font-size: 13px;
  color: #A8A8A8;
  letter-spacing: 0.4px;
}

.dropzone-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.dropzone-preview {
  width: 80%;
  position: relative;

  &:hover .dropzone-button {
    display: block;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

}

</style>
