<template>
  <div class="card">

    <div class="mt-1">
      Старт проекта <span class="badge text-bg-secondary">{{ project.start_date }}</span>
    </div>
    <div class="mt-1">
      ДДЛ проекта <span class="badge text-bg-secondary">{{ project.start_date }}</span>
    </div>
    <div class="card-header">
      <div class="row">
        <div class="col">
          <CountryChoice
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="project.countries"
              v-bind:changeCountry="changeCountry">
          </CountryChoice>
        </div>
        <div class="col">
          <span>Период:</span>
          <div class="d-flex align-items-center" style="gap: 10px">
            <date-picker
                v-model="dates"
                locale="ru"
                :clearable="false"
                format="dd.MM.yyyy"
                :range="true"
                model-type="yyyy-MM-dd"
                :auto-apply="true"
                :allowed-dates="allowedDates"
                :enable-time-picker="false"
                :multi-calendars="true"
            ></date-picker>
            <button class="btn btn-sm btn-outline-dark" @click="fillData">Показать</button>
          </div>
         <span class="mt-2"> {{ dates.map(x => $moment(x).format("DD.MM.YYYY")).join("-") }}</span>

        </div>
        <div class="col col-md-auto">
          <router-link :to="`/projects/${id}/forecast/edit/`" class="btn btn-primary btn-sm">Редактировать</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <label for="country" class="form-label">Ключевые слова</label>
          <select id="keyword" v-model="keyword" class="form-select" required="">
            <option value="">Выбрать...</option>
            <option v-for="key in keyword_filter" :key="key.id" :value="key.id">
              {{ key.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="state" class="form-label">Топ</label>
          <select id="state" v-model="state" class="form-select" required="">
            <option v-for="st in $STATES" :value="st.id">{{ st.name }}</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="state" class="form-label">Совместить данные</label>
          <br/>
          <button
              class="btn"
              title="Выровнять график и таблицу"
              @click="by_line = !by_line"
          >
            <i :class="`bi bi-toggle2-${by_line ? 'on' : 'off'}`"></i>
          </button>
        </div>
        <!--todo this func crash page, rewrite, find ta-->
        <!--        <div class="col-md-1">-->
        <!--          <label v-if="weekly_data_display" for="state" class="form-label">Показываются данные за неделю</label>-->
        <!--          <label v-else for="state" class="form-label">Показываются данные по отчетным дням</label>-->
        <!--          <br/>-->
        <!--          <button-->
        <!--              class="btn"-->
        <!--              title="Выровнять график и таблицу"-->
        <!--              @click="weekly_data_display = !weekly_data_display"-->
        <!--          >-->
        <!--            <i :class="`bi bi-toggle2-${weekly_data_display ? 'on' : 'off'}`"></i>-->
        <!--          </button>-->
        <!--        </div>-->

      </div>
    </div>
    <div style="overflow: hidden" class="">
      <div v-if="chart_data && chart_data.labels" :class="`card-body card ${by_line?'stat':''} p-0 m-2`">
        <div id="legend-container" class="legend"></div>
        <div :class="`${by_line?'chart-by-line':''}`"
             :style="`${by_line?`width: ${(chart_data.labels.length-1)*161+380}px;`:''} height: 80vh;`">

          <Line
              v-if="chart_data"
              :options="chartOptions"
              :data="chart_data"
              :plugins="[htmlLegendPlugin]"
          />
        </div>

        <template v-if="stat">
          <div :class="`${by_line?'':'table-responsive'}`"
               :style="by_line?`padding-right: ${(chart_data.labels.length-Object.keys(forecast_stat.dates).length)*160}px`:'overflow-x: scroll;'">
            <table class="table text-center">
              <thead>
              <tr>
                <th class="fixed" rowspan="3"></th>
                <td v-for="date in forecast_stat.dates" colspan="2" width="160" class="table-head">
                  <div class="h5 head">{{ date }}</div>
                </td>
              </tr>
              <tr>
                <template v-for="date in forecast_stat.dates">
                  <th><i class="yandex"></i></th>
                  <th><i class="google"></i></th>
                </template>
              </tr>
              <tr>
                <th v-for="date in forecast_stat.dates" colspan="2" class="text-center">
                  <b>{{
                      forecast_stat.keys.map(x => x.dates[date]["yandex"] + x.dates[date]["google"]).reduce((a, b) => a + b, 0)
                    }}</b>
                </th>
              </tr>
              </thead>
              <tbody>

              <tr class="table-light">
                <th :class="`fixed text-start${by_line?' by_line':''}`">
                  {{ project.name }}
                </th>
                <template v-for="date in forecast_stat.dates">
                  <td v-for="engine in $ENGINES">
                    <router-link
                        :to="{
                                                name: 'links_info',
                                                params: { id: $route.id },
                                                query: {
                                                state: state,
                                                engine: engine,
                                                tone: tone.split(','),
                                                date: date,
                                                uniq: '',
                                                country_code: active_country.code
                                                },
                                            }"
                    >
                      <span class="text-dark"> {{
                          forecast_stat.keys.map(x => x.dates[date][engine]).reduce((a, b) => a + b, 0)
                        }}</span>
                    </router-link>
                  </td>
                </template>
              </tr>
              <tr v-for="key in forecast_stat.keys">
                <th :class="`text-start fixed${by_line?' by_line':''}`">
                  {{ key.name }}
                </th>
                <template v-for="date in forecast_stat.dates">
                  <td v-for="engine in $ENGINES">
                    <router-link
                        :to="{  name: 'links_info',
                                                params: { id: $route.id },
                                                query: {
                                                    state: state,
                                                    engine: engine,
                                                    key: key.id,
                                                    tone: tone.split(','),
                                                    date: date,
                                                    uniq: '',
                                                    country_code: active_country.code
                                                },
                                            }"
                    >
                      <span class="text-dark"> {{ key.dates[date][engine] || "-" }}</span>
                    </router-link>
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from "moment";
import RangeSlider from "@/plugins/RangeSlider"

import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: "ProjectForecast",
  components: {
    Line,
    RangeSlider
  },

  props: ["id", "stat"],
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      current_date: moment(),
      active_date: moment(),
      old_state: null,
      by_line: false,
      state: null,
      keyword: null,
      tone: "negative",
      active_country: {},
      by_date: false,
      project: {},
      forecasts: [],
      keywords: [],
      datacollection: null,
      weekly_data_display: true,
      project_report_weekday: '',
      stock_datacollection: {},
      dates: [],
      allowedDates: [],
      forecast_stat: {},

      data_set_options: {
        'inner': {
          'type': 'line', 'label': 'План', 'fill': 'false',
          'borderColor': 'rgb(104, 234, 172)', 'lineTension': 0
        },
        'outer': {
          'type': 'line', 'label': 'План для клиента', 'fill': 'false',
          'borderColor': 'rgb(162, 48, 160)', 'lineTension': 0
        },
        'real': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов', 'fill': 'false',
          'borderColor': 'rgb(0, 0, 0)', 'lineTension': 0
        },
        'g': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов google', 'fill': 'false',
          'borderColor': 'rgb(102, 112, 192)', 'lineTension': 0
        },
        'y': {
          'type': 'line', 'label': 'Сумма нецелевых ресурсов yandex', 'fill': 'false',
          'borderColor': 'rgb(255, 0, 0)', 'lineTension': 0
        }
      }
    };
  },
  computed: {
    keyword_filter() {
      return this.keywords.filter(x => (x.countries.find(c => c.code === this.active_country.code) || !x.countries))
    },
    chartOptions() {
      let $this = this, d = moment().day() - (moment().day() ? 1 : -6)
      let week_start = moment().subtract(d + (d <= this.project.report_weekday ? 7 : 0) - this.project.report_weekday, "days");

      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {

          x:
              {
                grid: {
                  display: false
                },
                ticks: {
                  backdropColor: function (x) {
                    return x.tick.label === $this.$moment(new Date).format('YYYY-MM-DD') ? '#ddd' : '#fff'
                  },
                  showLabelBackdrop: true,
                  font: function (x) {
                    let label_date = moment(x.tick.label, 'YYYY-MM-DD')
                    return (moment(label_date).isSameOrAfter(week_start, 'day') && label_date <= moment()) ? {
                      size: 14,
                      weight: 'bold'
                    } : {size: 12}
                  },
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                },
              },
          y:
              {
                afterFit: function (scaleInstance) {
                  if ($this.by_line) scaleInstance.width = 380;
                },
                grid: {
                  display: false
                },
                ticks: {
                  display: false,
                },
                maxTicksLimit: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
                suggestedMax: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
                max: (Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => typeof x === 'number')) || 0) + 10,
              },

        },
        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      }
    },
    // projectCalc() {
    //   const $this = this;
    //   let subProject = structuredClone($this.forecast_stat)
    //   if (subProject.dates && !$this.by_date) {
    //     let dates = Object.keys(subProject.dates)
    //     subProject.dates = Object.fromEntries(Object.entries(subProject.dates).filter(([key]) => dates.indexOf(key) + 1));
    //     let newKeys = []
    //     subProject.keys.forEach(function (key) {
    //       newKeys.push(key);
    //     })
    //     subProject.keys = newKeys;
    //   }
    //   return subProject;
    // },
    chart_data() {
      // stock_datacollection был клонирован из первозданного datacollection в fillData
      // поэтому клонируем его обратно чтобы полноценны пересчитать даты для недели или отчетных дней
      this.datacollection = structuredClone(this.stock_datacollection);
      if (this.datacollection && this.datacollection.labels) {
        let new_data = [];
        for (let i in this.datacollection.datasets) {
          let d = this.datacollection.datasets[i];
          let n_d = {
            borderColor: d.borderColor,
            backgroundColor: d.borderColor,
            data: d.data,
            fill: false,
            label: d.label,
            lineTension: 0,
            borderWidth: 2,
            pointRadius: 5
          };
          new_data.push(n_d);

        }

        // пересчет графика
        if (!this.weekly_data_display) {
          let count_report_weekday = 0

          for (let i = this.datacollection.labels.length - 1; i >= 0; i--) {
            if (count_report_weekday > 2) {
              break
            }
            if (moment(this.datacollection.labels[i]).isoWeekday() - 1 === this.project_report_weekday) {
              count_report_weekday += 1
            } else {
              this.datacollection.labels.splice(i, i)
            }
          }

        }

        return {
          labels: this.datacollection.labels,
          datasets: new_data,
        };
      } else return this.datacollection;

    },
  },
  watch: {
    active_country: function () {
      this.fillData();
    },
    keyword: function (val) {
      this.fillData();
    },
    state: function (val) {
      if (this.old_state !== null) {
        this.fillData();
        this.project.active_state = val;
        ProjectApi.update(this.project)
      }
      this.old_state = val;
    },
    by_date: function (val) {
      this.fillData();
    },
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.id).then(response => {
      $this.project = response;
      $this.dates = [
        $this.project.start_date ? $this.project.start_date : moment().add(-60, "days").format("YYYY-MM-DD"),
        $this.project.end_date ? $this.project.end_date : moment().format("YYYY-MM-DD")
      ]
      ProjectApi.get_avail_date_calendar($this.$route.params.id).then(response => $this.allowedDates = response)
      $this.state = $this.project.active_state;
      $this.active_country = $this.project.active_country_code;
      this.getKeywords()
    })
  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
      }
    },
    formatten(value) {
      if (this.datacollection) {
        let val = this.datacollection.labels[value];
        if (!val) val = this.datacollection.labels[value - 1]
        return `${val}`;
      }
      return '';
    },
    getKeywords() {
      ProjectApi.light_keywords(this.id).then((response) => this.keywords = response);
    },
    forecastStat() {
      let $this = this;
      if ($this.stat) {
        let $this = this;
        let filters = {
          state: $this.state,
          keyword: $this.keyword ? $this.keyword : "",
          country: $this.active_country ? $this.active_country.code : "RU",
          date_start: $this.dates[0],
          date_end: $this.dates[1]
        };
        let f_string = new URLSearchParams(filters).toString();
        ProjectApi.get_forecast_stat($this.id, f_string).then(
            (response) => {
              $this.forecast_stat = response;
            }
        );
      }

    },
    fillData() {
      let $this = this;
      ProjectApi.get_chart_data($this.id, {
        state: $this.state,
        keyword: $this.keyword,
        by_date: $this.by_date,
        country: $this.active_country.code,
        date_start: $this.dates[0],
        date_end: $this.dates[1],
      }).then((response) => {
        this.project_report_weekday = response.report_weekday


        let new_data = [];
        let data_set_options_keys = Object.keys(this.data_set_options);

        for (let i = 0; i < data_set_options_keys.length; ++i) {
          let data_set_key = data_set_options_keys[i]


          let getted_data = []
          for (let j in response.data) {
            getted_data.push(response.data[j][data_set_key])

          }

          let n_d = {
            borderColor: this.data_set_options[data_set_key].borderColor,
            data: getted_data,
            fill: this.data_set_options[data_set_key].fill,
            label: this.data_set_options[data_set_key].label,
            lineTension: this.data_set_options[data_set_key].lineTension,
            type: this.data_set_options[data_set_key].type,
          };
          new_data.push(n_d);
        }

        $this.datacollection = {
          labels: Object.keys(response.data),
          datasets: new_data,
        };

        // Клонируем datacollection, чтобы потом использовать его для переключения графика для недели или отчетных дней
        this.stock_datacollection = structuredClone(this.datacollection);
        $this.forecastStat()
      });

    },
  },
};
</script>

<style scoped>
.chart-by-line {
  height: 400px;
}

.card.stat {
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 50px;
}


.fixed {
  position: sticky;
  top: 0;
  left: 0;
  white-space: nowrap;
}

.fixed.by_line {
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  overflow: hidden;
}

.fixed:not(.gray-bg) {
  background: #fff;
}

.fixed + div {
  /*margin-left: 350px!important;*/
}

.head {
  display: block;
  width: 160px;
  margin: 0;
  padding: 0;

}

.uniq-p {
  padding-top: 40px;
}
</style>
