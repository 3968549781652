import { HTTP } from './common'

export const PdfApi = {

  load_pdf(object) {

      return HTTP.post("pdf/load_pdf/", object,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
        return response.data;
      });
  },
  project_info(project_id) {

      return HTTP.get("pdf/project_info/?project_id="+project_id).then((response) => {
        return response.data;
      });
  },


};
