<template>
  <div class="table-responsive d-flex position-relative search-results">
    <table class="table sticky numbers" v-if="keywords.length">
      <thead class="sticky-top table-head">
      <tr>
        <th scope="col" class="text-center no-wrap">#</th>
      </tr>
      <tr class="text-center table-sub-head">
        <td><i>-</i></td>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td :class="full_url?' full-url':''">
          <table class="table result">
            <tbody>
            <tr :class="full_url?' full-url':''"
                v-for='index in Math.max(...keywords.map(x=>Math.max(...[x["searches"]["yandex"].length, x["searches"]["google"].length])))'>
              <td :class="`no-wrap link_info engine_info${full_url?' full-url':''}`" style="width: 40px">
                <div class="inline-block">
                  <div>
                    <span>{{ index }}</span>
                  </div>
                </div>
              </td>
            </tr>

            </tbody>
          </table>

        </td>
      </tr>
      </tbody>
    </table>
    <table class="table" v-for="(key, index) in keywords">
      <thead class="sticky-top table-head">
      <tr>
        <th scope="col" class="text-center no-wrap border-end" :colspan="engines.filter(x=>x.active).length">{{
            key.name
          }}
        </th>
      </tr>
      <tr class="text-center table-sub-head">
        <th v-for="(engine, index) in engines.filter(x=>x.active)"
            :class="engines.filter(x=>x.active).length === index+1?'border-end':''">
          <i :class="engine.name"></i>
        </th>

      </tr>
      </thead>

      <tbody>

      <tr :class="full_url?' full-url':''">
        <template v-for="(engine, index) in engines.filter(x=>x.active)">
          <td :class="engines.filter(x=>x.active).length === index+1?'border-end':''">
            <table class="table result">

              <tbody>
              <tr v-for="link in key.searches[engine.name]">
                <td :class="`border-bottom no-wrap link_info ${link.type_tone} ${show_our?'show-our':''} engine_info${full_url?' full-url':''}`"
                    :id="'link-'+link.id">
                  <popper hover offsetDistance="5">
                    <a
                        :href="link.link"
                        target="_blank"
                        :class="`domain-max${show_non_check&&!link.check?' f-bold':''}${bold_link===link.link_obj_id?' fw-bold link_display':''}`"
                        @click.exact="$parent.linkInfo(link.link_obj_id, true, link, $event)"
                        @click.shift.exact="click_on_link_to_display(link.link_obj_id, $event)"
                        :style="getClass(link)" :ref="`link-${link.link_obj_id}`"
                    >{{ full_url ? link.link : link.domain }}
                    </a>

                    <template #content>
                      <div class="popper text-start">
                        <a :href="link.link">{{ link.link }}</a>
                        <br>
                        <div class="row">
                          <b class="col-12">Тон</b>
                          <div class="col-12">
                            <template
                                v-if="project.project_type !== 'archive' && $store.getters.permission.includes('link_edit')">
                              <ul class="dropdown list">
                                <template v-for="(tone, key) in $TONES">
                                  <li v-if="!tone.no_filter"
                                      v-on:click="
                                                  link.tone = key;
                                                  link.type_tone='new';
                                                  link.kind_type='manual';
                                                  updateLink(link)"
                                      :class="`dropdown-item ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                                    {{ tone.name }}
                                  </li>
                                </template>
                              </ul>
                            </template>
                            <template v-else>{{ $TONES[link.tone]?.name }}</template>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <b class="col-12">Тип</b>
                          <div class="col-12">
                            <template v-if="link.tone">
                              <template
                                  v-if="project.project_type !== 'archive' && ($store.getters.permission.indexOf('link_edit') + 1)">
                                <ul class="dropdown list">
                                  <li v-for="(tone, key) in $getTypes(link.tone)"
                                      v-on:click="link.type_tone = key; updateLink(link)"
                                      :class="`dropdown-item pb-0 pt-0 ${link.type_tone === key?'active':''}`">
                                    {{ tone }}
                                  </li>
                                </ul>
                              </template>
                              <template v-else>{{ $getTypes(link.tone)[link.type_tone] }}</template>
                            </template>


                          </div>
                        </div>


                        <br>
                        Вид: {{ link.kind_type_name }}
                        <br>
                        <template v-if="link.error">
                          <b>Ошибка получения контента</b>
                          <br>
                        </template>
                        <template v-if="link.cross">
                          <b>Пересечение с выдачей изображений</b>
                          <br>
                        </template>
                        Метод воздействия: {{ link.delete_type }}
                        <br>
                        Комментарий: {{ link.comment }}
                      </div>
                    </template>
                  </popper>

                  <button class="btn btn-light copy" title="Копировать ссылку"
                          @click.prevent="copyText(link.link)">
                    <i class="bi bi-clipboard copy" @click.prevent="copyText(link.link)"></i>
                  </button>
                </td>

              </tr>

              </tbody>
            </table>
          </td>
        </template>
      </tr>

      </tbody>
    </table>
    <table class="show-more" v-if="(pager.page * pager.size)< pager.all && pager.show" @click="pager.upPage()">
      показать ещё <i class="bi bi-chevron-double-right"></i>
    </table>
  </div>
</template>

<script>

import {LinkApi} from "@/api/project";

export default {
  name: "SearchResult",
  props: {
    keywords: {type: Object},
    full_url: {type: Boolean},
    show_our: {type: Boolean},
    group_color: {type: Boolean},
    show_non_check: {type: Boolean},
    engines: {type: Object},
    project: {type: Object},
    pager: {type: Object},
    country_code: {type: String},
  },
  components: {},
  data() {
    return {
      bold_link: '',
    }
  },
  watch: {},
  mounted() {

  },
  methods: {
    getClass: function (link) {
      if (this.group_color) {
        return `color: rgb(${link.group_color?link.group_color:[0,0,0]})`
      } else {
        if (link.error && !link.tone) return `color: #ff8c39`;
        else {
          let COLORS = this.$COLORS;
          let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
          return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
        }
      }

    },
    copyText(text) {
      const $this = this;
      navigator.clipboard.writeText(text).then(function () {
        $this.$notify({
          type: 'success ',
          text: 'Ссылка скопирована'
        });
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    click_on_link_to_display(link_obj_id, event) {
      event.preventDefault()
      if (this.bold_link === link_obj_id) {
        this.bold_link = ''
      } else {
        this.bold_link = link_obj_id
      }
    },
    updateLink: function (link) {
      let $this = this,
          link_data = {
            id: link.link_obj_id,
            tone: link.tone,
            type_tone: link.type_tone,
            kind_type: 'manual'
          }

      LinkApi.update(link_data, this.project.id).then(() => {
        $this.keywords.forEach(f => {
          ["yandex", "google"].forEach(e => {
            f.searches[e].forEach(y => {
              if (y.link_obj_id === link_data.id) {
                y.tone = link_data.tone
                y.type_tone = link_data.type_tone
              }
            })
          })
        })

      })
    },
  }
}
</script>
<style>
.show-more {
  position: sticky;
  background: #F4F4F4FF;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 98px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
}
</style>
<style scoped lang="scss">
.search-results {
  align-items: start;
  overflow: auto;
}

.numbers {
  background: #fff;
  z-index: 10;

  tr {
    background: #fff !important;

    &.full-url td {
      display: flex;
      align-items: center;
    }

    td {
      background: #fff !important;
    }
  }
}
.show-our:not(.our) a{
  color: transparent!important;
}

table {
  border-collapse: separate;
  border-spacing: 0;

  thead {
    top: -20px;
    box-shadow: 0 5px 28px -12px rgba(0, 0, 0, 0.51);
  }

  tr {
    height: 35px !important;
  }
}
</style>