import AuthyFront from '../AuthyFront.vue'
import AuthyDetail from '../AuthyDetail.vue'


export default [
    {
        path: '/authy',
        name: 'authy',
        component: AuthyFront,
        meta: {
            requiresAuth: true,
            base_name: "authy",
            // hasPerm: true,
            title: 'Authy',
            // hasPerm: true
        },

    },
    {
                path: '/authy/:code_id',
                name: 'authy_detail',
                component: AuthyDetail,
                meta: {
                    requiresAuth: true,
                    base_name: "authy",
                    title: 'Authy'
                }
            }
]
