<template>
  <modal modal_id="show_document">
    <template v-slot:header>
      <div class="col">
        <h2>{{ document.file_name }}</h2>
      </div>
      <div class="me-3" style="display: flex; flex-direction: column">
        <button v-if="document.check_process" class="btn btn-sm btn-warning" style="white-space: nowrap">
          <span class="loading">проверка</span>
        </button>
        <button v-else class="btn btn-sm btn-outline-warning text-black" style="white-space: nowrap"
                @click="recalcUnique">
          Проверить уникальность
        </button>
        <small class="last-update text-secondary" v-if="document.last_check">Последняя проверка:
          {{ $moment(document.last_check).format('DD.MM.YYYY HH:MM') }}
        </small>
      </div>
    </template>
    <template v-slot:body>
      <div class="modal-body">
        <div class="alert" v-if="save_error">
          <div class="wrap">
            <div class="row">
              <div class="col-1"><h1 class="text-danger"><i class="bi bi-exclamation-triangle-fill"></i></h1></div>
              <div class="col"><h5>В базе данных обнаружены новые изменения документа. При сохранении текущая версия
                заменит имеющуюся.</h5>
                <small>Для получения нового текста откройте документ заново или обновите страницу</small>
              </div>
            </div>

            <div class="row mt-2 ">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-sm btn-warning me-2"
                        @click="document.save_anyway=true;saveDoc();save_error=false;document.save_anyway=false;">
                  Сохранить
                </button>
                <button class="btn btn-sm btn-outline-primary" @click="save_error=false;">Отмена</button>
              </div>
            </div>
          </div>
        </div>
        <div class="alert" v-if="update_version">
          <div class="wrap">
            <div class="loader" v-if="update_version_wait">
            </div>
            <div class="row">
              <div class="col-1"><h1 class="text-primary"><i class="bi bi-question-circle-fill"></i></h1></div>
              <div class="col"><h5>Заменить текущую версию текста на версию от
                {{ $moment(update_version.created_at).format('DD.MM.YYYY HH:mm') }}?</h5>
                <small>После восстановления все имеющиеся пересечения будут очищены, потребуется повторный запуск
                  проверки уникальности</small>
              </div>
            </div>

            <div class="row mt-2 ">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-sm btn-warning me-2" @click="restoreDoc(update_version.id);">Заменить</button>
                <button class="btn btn-sm btn-outline-primary" @click="update_version=null;">Отмена</button>
              </div>
            </div>
          </div>
        </div>
        <div class="alert" v-if="send_to_confirm">
          <div class="wrap">

            <div class="row">
              <div class="col-1"><h1 class="text-primary"><i class="bi bi-question-circle-fill"></i></h1></div>
              <div class="col"><h5>Отправить текущий текст на согласование клиенту?</h5>
                <small>После подтверждения, клиенту отправится текущая версия текста</small>
              </div>
            </div>

            <div class="row mt-2 ">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-sm btn-warning me-2" @click="sendConfirmDoc()">Отправить</button>
                <button class="btn btn-sm btn-outline-primary" @click="send_to_confirm=false;">Отмена</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                <div class="ms-2 me-auto">
                  Внутренняя уникальность
                </div>
                <span class="badge bg-secondary">{{
                    document.internal_uniqueness !== null ? round(internal) : '-'
                  }}%</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                <div class="ms-2 me-auto">
                  Внешняя уникальность
                </div>
                <span class="badge bg-secondary">{{
                    document.external_uniqueness !== null ? round(external) : '-'
                  }}%</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                <div class="ms-2 me-auto">
                  <span class="fw-bold">Общая уникальность</span>
                </div>
                <span class="badge bg-secondary">{{
                    document.total_uniqueness !== null ? round(total) : '-'
                  }}%</span>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Всего символов
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.n_chars_with_space }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Без пробелов
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.n_chars_without_space }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Всего слов
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.n_words }}</span>
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Орфография
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.spell_mistakes }}</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Заспамленность
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.spam_percent }}%</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Вода
                </div>
                <span class="badge bg-secondary"
                      v-if="document.external_stat">{{ document.external_stat.water_percent }}%</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-2">
          <div :class="`form-group ${reference_text?'col-6':''}`">
            <div class="input-group">
              <input :readonly="!edit_name" class="form-control file_name" :disabled="!edit_name"
                     placeholder="Название загруженного текста"
                     name="file_name"
                     v-model="document.file_name">
              <button v-if="!edit_name" class="btn btn-warning input-group-text" @click="edit_name=true">
                <i class="bi bi-pencil"></i>
              </button>
              <button v-else class="btn btn-success input-group-text" @click="saveDoc(false);edit_name=false;">
                Сохранить
              </button>
            </div>
            <Editor v-bind:words="calc_words" v-if="renderEditor"></Editor>

          </div>
          <div class="form-group col-6" v-if="reference_text">
            <div class="input-group">
              <input :readonly="true" class="form-control file_name" :disabled="true"
                     placeholder="Название загруженного текста"
                     name="file_name"
                     v-model="reference_text.file_name">
              <button class="btn btn-warning input-group-text"
                      @click="clickInner(reference_text.id, true); reference_text=null">
                <i class="bi bi-x-circle-fill"></i>
              </button>
            </div>
            <Editor v-bind:words="reference_text?.calc_words" v-if="reference_text?.renderEditor"></Editor>

          </div>
        </div>
        <div :class="`row mt-2 ${reference_text?'col-6 no-wrap':''}`">
          <div class="col">
            <template v-if="document.ya_disk_url">
              <a target="_blank" class=" text-truncate"
                 style="max-width: 350px; display: inline-block;" :href="document.ya_disk_url">{{
                  document.ya_disk_url
                }}</a>
              <button class="btn btn-sm btn-warning ms-2" @click="updateText">
                <template v-if="!reference_text">Обновить текст</template>
                <i v-else class="bi bi-arrow-clockwise"></i>
              </button>
            </template>
          </div>
          <div class="col d-flex justify-content-end">
            <button class="btn btn-sm btn-success" @click="saveDoc">Сохранить</button>
            <button class="btn btn-sm btn-outline-primary ms-2" @click="downloadDoc">
              <template v-if="!reference_text">Скачать docx</template>
              <i class="bi bi-file-arrow-down"></i>
            </button>
          </div>
        </div>
        <div class="row mt-3 d-flex">
          <div class="col d-flex justify-content-end">
            <!--            <button class="btn btn-sm btn-danger mb-1 me-2" @click="send_to_confirm=true">-->
            <!--             Согласование с клиентом<i class="bi bi-check2"></i></button>-->
            <button class="btn btn-sm btn-warning mb-1" @click="getVersions">Версии текста <i
                :class="`bi bi-chevron-${show_versions?'up':'down'}`"></i></button>
          </div>
        </div>
        <div class="list-group" v-if="show_versions">
          <small v-if="!versions?.length">Отсутствуют версии текста</small>
          <div class="list-group-item list-group-item-action" aria-current="true" v-for="version in versions">
            <div class="d-flex w-100 justify-content-between mb-1">
              <small>
                <b>{{ $moment(version.created_at).format('DD.MM.YYYY HH:mm') }}</b>
                <a :href="version.text"
                   target="_blank"
                   class="ms-2">Текст</a></small>

              <button class="btn btn-sm btn-success" @click="update_version=version"><small>восстановить</small> <i
                  class="bi bi-arrow-clockwise"></i>
              </button>
            </div>
            <div class="row">
              <div class="col" v-if="version.info">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                    <div class="ms-2 me-auto">
                      Внутренняя
                    </div>
                    <span class="badge bg-secondary">{{ version.info.internal_uniqueness }}%</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                    <div class="ms-2 me-auto">
                      Внешняя
                    </div>
                    <span class="badge bg-secondary">{{ version.info.external_uniqueness }}%</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start no-wrap">
                    <div class="ms-2 me-auto">
                      <span class="fw-bold">Общая</span>
                    </div>
                    <span class="badge bg-secondary">{{ round(version.info.total_uniqueness) }}%</span>
                  </li>
                </ul>
              </div>
              <div class="col" v-if="version.info">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Всего символов
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{ version.info.external_stat.n_chars_with_space }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Без пробелов
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{
                        version.info.external_stat.n_chars_without_space
                      }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Всего слов
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{ version.info.external_stat.n_words }}</span>
                  </li>
                </ul>
              </div>
              <div class="col" v-if="version.info">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Орфография
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{ version.info.external_stat.spell_mistakes }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Заспамленность
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{ version.info.external_stat.spam_percent }}%</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                      Вода
                    </div>
                    <span class="badge bg-secondary"
                          v-if="version.info.external_stat">{{ version.info.external_stat.water_percent }}%</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-3">
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Внешняя уникальность
                </div>
                <span class="badge bg-secondary">{{ Math.round(external) }}%</span>
              </li>
            </ul>
            <div class="mt-1" v-if="document.external && document.external?.length">
              <p>Домены, на которых найдены совпадения (text.ru)</p>
              <ul class="list-group">

                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto col-8"></div>
                  <div class="row no-wrap nowrap">
                    <div class="col"></div>
                    <div class="col">
                      <button class="btn btn-sm" id="external_eye"
                              @click="updateVal(document.external, 'hide', settings.ex_show); settings.ex_show = !settings.ex_show">
                        <i :class="`bi bi-eye-fill ${settings.ex_show?' text-primary':''}`"></i>
                      </button>
                    </div>
                    <div class="col">
                      <button class="btn btn-sm"
                              @click="updateVal(document.external, 'off_calc', settings.ex_calc); settings.ex_calc = !settings.ex_calc">
                        <i :class="`bi bi-${settings.ex_calc?'x-lg text-danger':'arrow-90deg-up text-success'}`"></i>
                      </button>
                    </div>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"
                    v-for="site in document.external">
                  <div class="ms-2 me-auto col-8">
                    <a :href="site.url" target="_blank">{{ site.url }}</a>
                  </div>
                  <div class="row no-wrap nowrap">
                    <div class="col">{{ Math.round(site.percent) }}%</div>
                    <div class="col">
                      <button v-if="!site.off_calc" class="btn btn-sm" @click="updateShow(site)">
                        <i :class="`bi bi-eye-fill${!site.hide?' text-primary':''}`"></i>
                      </button>

                      <button class="btn btn-sm" v-else>
                        <i class="bi bi-eye text-white"></i>
                      </button>
                    </div>
                    <div class="col">
                      <button class="btn btn-sm" @click="site.off_calc = !site.off_calc">
                        <i :class="`bi bi-${!site.off_calc?'x-lg text-danger':'arrow-90deg-up text-success'}`"></i>
                      </button>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  Внутренняя уникальность
                </div>
                <span class="badge bg-secondary">{{ Math.round(internal) }}%</span>
              </li>
            </ul>
            <div class="mt-1" v-if="document.internal && document.internal?.length">
              <p>Тексты, в которых найдены совпадения</p>
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto col-8"></div>
                  <div class="row no-wrap nowrap">
                    <div class="col"></div>
                    <div class="col">
                      <button class="btn btn-sm"
                              @click="updateVal(document.internal, 'hide', settings.in_show); settings.in_show = !settings.in_show">
                        <i :class="`bi bi-eye-fill${settings.in_show?' text-primary':''}`"></i>
                      </button>
                    </div>
                    <div class="col">
                      <button class="btn btn-sm"
                              @click="updateVal(document.internal, 'off_calc', settings.in_calc); settings.in_calc = !settings.in_calc">
                        <i :class="`bi bi-${settings.in_calc?'x-lg text-danger':'arrow-90deg-up text-success'}`"></i>
                      </button>
                    </div>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start"
                    v-for="site in document.internal">
                  <div class="ms-2 me-auto col-8">
                    <a href="" @click.prevent="getReference(site)">{{ site.name }}</a>
                  </div>
                  <div class="row nowrap">
                    <div class="col">{{ Math.round(site.percent) }}%
                    </div>
                    <div class="col">
                      <button class="btn btn-sm" v-if="!site.off_calc" @click="updateShow2(site)"
                              :id="`inner_${site.document2_id}`">
                        <i :class="`bi bi-eye-fill${!site.hide?' text-primary':''}`"></i>
                      </button>
                      <button class="btn btn-sm" v-else>
                        <i class="bi bi-eye text-secondary"></i>
                      </button>
                    </div>
                    <div class="col">
                      <button class="btn btn-sm" @click="site.off_calc = !site.off_calc">
                        <i :class="`bi bi-${!site.off_calc?'x-lg text-danger':'arrow-90deg-up text-success'}`"></i>
                      </button>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>


      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="$modal('hide', 'show_document');" data-bs-dismiss="modal">
        Отмена
      </button>
    </template>


    <modal modal_id="error_save">
      <div class="modal-content">
        <div class="modal-header justify-content-between">
          <div class="col">
            <div class="h3">454555s4</div>
          </div>

        </div>
        <div class="modal-body">


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$modal('hide', 'show_document');"
                  data-bs-dismiss="modal">
            Отмена
          </button>
        </div>
      </div>
    </modal>
  </modal>
</template>

<script>
import {DocumentApi} from "./api/document";
import router from "../router";
import Editor from "./includes/Editor.vue"
import {state} from "@/socket";

export default {
  name: "DocDetail",
  props: ['doc_id'],
  components: {
    Editor
  },
  data() {
    return {
      mark: true,
      edit: 'off',
      text: '',
      document: {},
      renderEditor: true,
      edit_name: false,
      reference_text: null,
      settings: {ex_show: true, ex_calc: true, in_show: true, in_calc: true,},
      words: [],
      old_text: '',
      last_check: false,
      show_versions: false,
      versions: [],
      save_error: false,
      update_version: null,
      update_version_wait: false,
      send_to_confirm: false
    }
  },
  watch: {
    edit() {
      document.designMode = this.edit;
    },
    doc_id(val) {
      this.getDocument();
    },
    state_doc(value) {
      if (value.object?.id === parseInt(this.doc_id)) {
        this.document = {...this.document, ...value.object}
      }
    },
    document(val) {
      if (val.content !== this.old_text || val.last_check !== this.last_check) {
        this.getMarkedText()
        this.forceRerender()
        this.versions = [];
        this.show_versions = false;
      }
    }
  },
  computed: {
    state_doc() {
      return state.doc
    },

    total() {
      let percent = this.document.total_uniqueness;
      if ((this.document.internal && this.document.external) &&
          (this.document.external.filter(x => x.off_calc).length || this.document.internal.filter(x => x.off_calc).length)) {
        let positions = this.document.external.filter(x => !x.off_calc).map(x => x.input_idxs).flat()
        let in_pos = this.document.internal.filter(x => !x.off_calc).map(x => x.input_idxs).flat()
        positions = positions.concat(in_pos);
        positions = Array.from(new Set(positions.map(JSON.stringify)), JSON.parse);
        percent = Math.ceil(100 - positions.length / this.document.external_stat.n_words * 100)
      }
      percent = percent > 100 ? 100 : percent < 0 ? 0 : percent
      return percent;
    },
    external() {
      let percent = this.document.external_uniqueness;
      if (this.document.external && this.document.external.filter(x => x.off_calc).length) {
        let positions = this.document.external.filter(x => !x.off_calc).map(x => x.input_idxs).flat();
        positions = Array.from(new Set(positions.map(JSON.stringify)), JSON.parse);
        percent = Math.ceil(100 - positions.length / this.document.external_stat.n_words * 100)
      }
      percent = percent > 100 ? 100 : percent < 0 ? 0 : percent
      return percent;
    },
    internal() {
      let percent = this.document.internal_uniqueness;
      if (this.document.internal && this.document.internal.filter(x => x.off_calc).length) {
        let positions = this.document.internal.filter(x => !x.off_calc).map(x => x.input_idxs).flat();
        positions = Array.from(new Set(positions.map(JSON.stringify)), JSON.parse);
        percent = Math.ceil(100 - positions.length / this.document.external_stat.n_words * 100)
      }
      percent = percent > 100 ? 100 : percent < 0 ? 0 : percent
      return percent;
    },
    positions() {
      let ex_positions = [], in_positions = [];
      if (this.document && this.document.external) {
        ex_positions = this.document.external.filter(x => !x.hide && !x.off_calc).map(x => x.input_idxs).flat();
        ex_positions = ex_positions.map(JSON.stringify)
      }
      if (this.document && this.document.internal) {
        in_positions = this.document.internal.filter(x => !x.hide && !x.off_calc).map(x => x.input_idxs).flat();
        in_positions = in_positions.map(JSON.stringify)
      }
      return {ex: ex_positions, in: in_positions}
    },
    calc_words() {
      let words = this.words, $this = this;
      words.forEach(word => {
        word.ex = !!($this.positions.ex.indexOf(word.id) + 1)
        word.in = !!($this.positions.in.indexOf(word.id) + 1)
      })
      return words;
    }

  },
  mounted() {
    this.getDocument();
  },
  methods: {
    async forceRerender() {
      this.renderEditor = false;
      await this.$nextTick();
      this.renderEditor = true;
    },
    router() {
      return router
    },
    getVersions(change_state = true) {
      if (!this.versions?.length || !change_state) DocumentApi.get_versions(this.doc_id).then((response) => this.versions = response)
      if (change_state) this.show_versions = !this.show_versions
    },
    getMarkedText() {
      let words = [], $this = this, idx = $this.document.input_idxs,
          last_index = $this.document.content.length + 1;
      if (!idx.length) idx = [[0, last_index]]
      idx.sort((a, b) => a[0] - b[0]);
      let prev = 0;
      idx.forEach(e => {
        if ((e[0] - prev) >= 1) words.push({val: $this.document.content.substring(prev, e[0]), empty: true})
        words.push({
          val: $this.document.content.substring(e[0], e[1]),
          ex: !!($this.positions.ex.indexOf(JSON.stringify(e)) + 1),
          in: !!($this.positions.in.indexOf(JSON.stringify(e)) + 1),
          empty: false,
          id: `${JSON.stringify(e)}`
        })
        prev = e[1];
      })
      if (prev < last_index) words.push({val: $this.document.content.substring(prev, last_index), empty: true})
      // $this.words = words;
      $this.words = words;
    },
    getDocument() {
      let $this = this;
      this.reference_text = null;
      this.settings = {ex_show: true, ex_calc: true, in_show: true, in_calc: true};
      DocumentApi.get(this.doc_id).then(response => {
        $this.document = response;
        $this.old_text = $this.document.content;
        $this.last_check = $this.document.last_check;
        $this.getMarkedText()
      });
      this.$modal('show', 'show_document');
    },
    recalcUnique() {
      let $this = this;

      DocumentApi.recalc_unique(this.doc_id).then(() => {
        $this.$notify({
          type: 'success',
          text: `Проверка уникальности "${$this.document.file_name}" запущена`
        })
        $this.getVersions(false)
      })
    },
    restoreDoc(id) {
      let $this = this;
      $this.update_version_wait = true;
      DocumentApi.restore_text(this.doc_id, id).then((response) => {
        $this.$notify({
          type: 'success',
          text: `Документ изменён`
        })
        $this.getDocument()
        $this.update_version = null;
        $this.update_version_wait = false;
        $this.getVersions(false)
      })

    },
    saveDoc(update = true) {
      let $this = this;
      if (update) $this.document.content = document.getElementsByClassName('redactor')[0].innerHTML.replace(/<\/p>/gi, "\n")
          .replace(/(<([^>]+)>)/gi, "")
      DocumentApi.update(this.document).then(response => {
        $this.document = {...$this.document, ...response};
        $this.$notify({
          type: 'success',
          text: 'Документ сохранён'
        })
        $this.getMarkedText()
        if (update) $this.recalcUnique("saveDoc")
      }).catch((error) => {
        if (error.response.status === 400 && error.response.data.updated_at !== undefined) {
          $this.save_error = true;
        }
      });
    },
    updateShow(site) {
      if (site.hide === undefined || site.hide || !this.document.external.filter(x => x.hide).length) this.document.external.forEach(x => x.hide = (x.id !== site.id))
      else if (!site.hide) this.document.external.forEach(x => x.hide = false)
    },
    updateShow2(site) {
      if (site.hide === undefined || site.hide || !this.document.internal.filter(x => x.hide).length) this.document.internal.forEach(x => x.hide = (x.id !== site.id))
      else if (!site.hide) this.document.internal.forEach(x => x.hide = false)
    },
    fileResponse: function (response, name) {
      let blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    },

    downloadDoc() {
      let $this = this;
      DocumentApi.download(this.document.id).then(response => {

        $this.fileResponse(response, `${$this.document.file_name}`)
      })
    },
    clickInner(id, all_hide = false) {
      if (all_hide) {
        let ex_hide = document.getElementById(`external_eye`);
        if (ex_hide) ex_hide.click();
      }
      let doc_link = document.getElementById(`inner_${id}`)
      if (doc_link) doc_link.click();
    },
    getReference(link) {
      let $this = this;
      if (!this.reference_text || this.reference_text.id !== link.document2_id) this.clickInner(link.document2_id, !this.reference_text)
      DocumentApi.get(link.document2_id).then(response => {
        $this.reference_text = response
        let in_positions = link.reference_idxs.map(JSON.stringify)
        let words = [], idx = $this.reference_text.input_idxs, last_index = $this.reference_text.content.length + 1;
        if (!idx.length) idx = [[0, last_index]]
        idx.sort((a, b) => a[0] - b[0]);
        let prev = 0;
        idx.forEach(e => {
          if ((e[0] - prev) >= 1) words.push({val: $this.reference_text.content.substring(prev, e[0]), empty: true})
          words.push({
            val: $this.reference_text.content.substring(e[0], e[1]),
            in: !!(in_positions.indexOf(JSON.stringify(e)) + 1),
            empty: false,
            id: `${JSON.stringify(e)}`
          })
          prev = e[1];
        })
        if (prev < last_index) words.push({val: $this.reference_text.content.substring(prev, last_index), empty: true})
        words.forEach(word => {
          word.in = !!(in_positions.indexOf(word.id) + 1)
        })
        $this.reference_text.calc_words = words;
        $this.reference_text.renderEditor = true;
      });
    },
    updateVal(items, field, val) {
      items.forEach(x => x[field] = val)
    },
    updateText() {
      let $this = this;
      DocumentApi.get_content_od(this.document.id).then(response => {
        if (response.content) {
          $this.document.content = response.content.text;
          $this.$notify({
            type: 'success',
            text: 'Текст обновлён, для сохранения и пересчёта уникальности нажмите "Сохранить"'
          })
          this.getMarkedText()
          this.forceRerender()

        } else if (response.error) {
          $this.$notify({
            type: 'error',
            text: response.error
          })
        }
      })
    },
    round(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    sendConfirmDoc() {

    }

  }
}
</script>
<style>
#show_document .modal-body {
  max-height: 85vh !important;
}
</style>

<style scoped lang="scss">

.ql-editor {
  height: 450px;
}

.popup {
  position: absolute;
  width: 250px;
  font-size: 12px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: none;
  right: 0;
  z-index: 10;
}

.popup.show {
  display: block;
}

.help {
  position: relative;
  cursor: pointer;
  font-size: 10px;
  margin-left: 5px;
}

.pager {
  width: 100px;
}

textarea {
  width: 100%;
  background: #eee;
}

.file_name {
  width: auto;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
  height: 100%;

  &:disabled {
    border: 0;
    background: #1CA5B8;
    color: #fff;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
  }
}

.modal.doc .modal-content {
  overflow-y: scroll;
  height: 100vh;
}

.redactor {
  height: 450px;
  padding: 10px;
  font: 20px/28px 'Open Sans', sans-serif;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  border: 2px solid #74637f;
  border-radius: 0;
  color: #444;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}

.redactor:focus {
  outline: none;
  box-shadow: 0 0 0 2px #c6aada;
}

.lds-ellipsis {
  width: 120px !important;
  height: 15px !important;
}

.lds-ellipsis div {
  top: 5px !important;
}

.redactor p {
  margin-bottom: 0;
}

.high {
  border-radius: 3px;
  background-color: #97D077 !important; /* or whatever */
}

.high2 {
  border-radius: 3px;
  background-color: #99CCFF !important; /* or whatever */
}

.alert {
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.alert .loader {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.alert .wrap {
  width: 50%;
  background: #fff;
  padding: 10px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  position: relative;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  width: 0;
  position: absolute;
  top: -18px;
  font-size: 26px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

</style>
