import { HTTP } from "./common";

export const ReportApi = {
  list(project_id,page, size) {
    return HTTP.get(`reports/?project_id=${project_id}&page=${page}&size=${size}`).then((response) => {
      return response.data;
    });
  },
 
  get(id) {
    return HTTP.get(`reports/${id}/`).then((response) => {
      return response.data;
    });
  },
  create_report(report) {
    return HTTP.post(`reports/`, report).then((response) => {
      return response.data;
    });
  },
  send_report_to_email(id, email) {
    return HTTP.get(`reports/${id}/send_report_to_email/?email=${email}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  restart(id) {
    return HTTP.get(`reports/${id}/restart/`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_report_setting() {
    return HTTP.get(`reports/get_report_setting/`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_slides(project_id) {
    return HTTP.get(`slides/?page=1&size=100&project_id=${project_id}`).then(
      (response) => {
        return response.data.results;
      }
    );
  },
};
