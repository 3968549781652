<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Оценка проекта: {{ project?.name }}</h2>
      <BackButton></BackButton>
    </div>
    <div class="filters mb-5 d-flex">
      <!--      <MultiSelect v-if="active_country?.code"-->
      <!--                   v-model="active_country"-->
      <!--                   :options="project.countries"-->
      <!--                   :multi="false"-->
      <!--                   :required="true"-->
      <!--                   name="Страна"></MultiSelect>-->
      <div role="search">
        <i class="bi bi-search"></i>
        <input type="search" class="form-control" placeholder="Поиск по ссылке" v-model="search" aria-label="Поиск">
      </div>
       <CountryChoice v-if="active_country"
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="project.countries"
              v-bind:changeCountry="changeCountry">
          </CountryChoice>

      <button class="btn btn-dark btn-sm" @click="getData();getStat();">Показать</button>
      <div class="date" style="margin-left: auto">
        {{ $moment(project?.last_parser).format("DD.MM.YYYY HH:mm") }}
      </div>

    </div>

    <div class="card border border-success" v-if="stat">
      <div class="card-body">
        <h4 class="card-title text-status">Целевая информация</h4>
        <div class="row">
          <template v-for="state in [10,20]">

            <div class="col p-1">
              <div class="">
                <div class="card-body ">
                  <h6 class="card-title"><b>Всего в Топ-{{ state }}</b></h6>
                  <hr>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Общий:</p>
                    <p class="place">
                      <b class="text-success">{{ stat['_' + state] }}</b>
                    </p>

                    <p class="place">
                      <i class="yandex"></i>
                      <b class="text-success">{{ stat['_' + state + '_y'] }}</b>
                    </p>
                    <p class="place">
                      <i class="google"></i>
                      <b class="text-success">{{ stat['_' + state + '_g'] }}</b>
                    </p>
                    <p class="place">
                      <b>%</b>
                      <b class="text-success">{{ stat['_' + state + '_percent'] }}</b>
                    </p>
                  </div>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Уникальный:</p>
                    <p class="place">
                      <b class="text-success me-2">{{ stat['_' + state + '_uniq'] }}</b>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                      <b class="text-success">{{ stat['_' + state + '_uniq_y'] }}</b>
                    </p>
                    <p class="place"><i class="google"></i>
                      <b class="text-success">{{ stat['_' + state + '_uniq_g'] }}</b>
                    </p>

                    <p class="place">
                      <b>%</b>
                      <b class="text-success">{{ stat['_' + state + '_uniq_percent'] }}</b>
                    </p>

                  </div>
                </div>
              </div>
            </div>

          </template>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="height: 100%">
      <table class="table">
        <thead>
        <tr class="sticky-top">
          <th colspan="6"></th>
          <th v-for="keyword in keywords" colspan="2">{{ keyword.name }}</th>
        </tr>
        <tr class="sticky-top">
          <th>№</th>
          <th>URL</th>
          <th>Площадка <a href="" @click.prevent="setOrder('pf_platform_name')">
            <i :class="'bi bi-caret-'+getOrderClass('pf_platform_name')"></i></a></th>
          <th>Дата публикации<a href="" @click.prevent="setOrder('date_publication')">
            <i :class="'bi bi-caret-'+getOrderClass('date_publication')"></i></a></th>
          <th>Дата индексации в Yandex</th>
          <th>Дата индексации в Google</th>
          <template v-for="keyword in keywords">
            <th v-for="engine in $ENGINES">{{ engine }}</th>
          </template>
        </tr>

        </thead>
        <tbody>
        <template v-for="(link, index) in table">
          <tr>
            <td>{{ index + 1 + (page - 1) * size }}</td>
            <td><a :style="$func.getClass(link)" style="width: 500px; display: block; word-break: break-all;"
                   target="_blank" :href="link.link">{{ link.link }}</a></td>
            <td>{{ getPlatformName(link) }}</td>
            <td><span v-if="link.date_publication">{{ $moment(link.date_publication).format("DD.MM.YYYY") }}</span></td>
            <td v-for="engine in $ENGINES">
              {{
                link.searches[engine + "_index"] ? $moment(link.searches[engine + "_index"]).format("DD.MM.YYYY") : "-"
              }}
            </td>
            <template v-for="keyword in keywords">
              <td v-for="engine in $ENGINES">
                <span
                    :class="link.searches.results.find(x => x.keyword_id == keyword.id && x.engine == engine)?.place>20?'text-danger':''">{{
                    link.searches.results.find(x => x.keyword_id == keyword.id && x.engine == engine)?.place || "-"
                  }}</span>
              </td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
      <nav>
        <ul class="pagination justify-content-center fz-9" v-if="pager.length">
          <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
            <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
            <span class="page-link" v-else>{{ p }}</span>
          </li>
        </ul>
      </nav>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import CountryChoice from "@/components/CountryChoice.vue";
import HTTP from "@/api/common";

export default {
  name: 'ProjectStat',
  components: {
    CountryChoice
  },

  data() {
    return {
      table: [],
      project: null,
      active_country: null,
      all_count: 0,
      size: 50,
      page: 1,
      order: '',
      search: '',
      stat: {}

    }
  },
  computed: {
    keywords() {
      return this.project?.keywords.filter(x => !x.countries.length || x.countries.find(c => c.code === this.active_country.code))
    },
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
  },
  watch: {
    page() {
      this.getData();
    }
  },
  mounted: function () {
    let $this = this;
    HTTP.get(`planfix/target/get_project/?project_id=${this.$route.params.id}`).then((response) => {
      $this.project = response.data;
      $this.active_country = $this.project.countries[0];
      $this.getData()
      $this.getStat()
    });

  },
  methods: {
    getStat(){
      let $this = this;
      let data = {
        country_code: this.active_country.code,
        project_id: this.$route.params.id,
      }
      let query = new URLSearchParams(data).toString();
      HTTP.get(`planfix/target/get_pf_stat/?${query}`).then((response) => {
        $this.stat = response.data;
      });
    },
    changeCountry(country){
      if (this.active_country.code !== country.code) {
        this.active_country = country;
      }
    },
    getOrderClass(name) {
      let style = "down"
      if (this.order.includes(name)) {
        if (this.order.includes('-')) style = "up"
        style += "-fill"
      }
      return style;
    },
    setOrder(name) {
      if (!this.order.includes(name)) this.order = name;
      else if (this.order.includes("-")) this.order = "";
      else this.order = '-' + this.order
      this.getData();
    },
    getPlatformName(link) {
      // if (link.pf_platform_name) return link.pf_platform_name;
      return link.pf_platform_name;
      // return new URL(link.link).host
    },
    getData() {
      let $this = this;
      let data = {
        country_code: this.active_country.code,
        project_id: this.$route.params.id,
        page: this.page,
        size: this.size,
        search: this.search,
        order: this.order,
      }
      let query = new URLSearchParams(data).toString();
      HTTP.get(`planfix/target/get_pf_links/?${query}`).then((response) => {
        $this.table = response.data.results
        $this.all_count = response.data.count;
      });
    }

  }
}

</script>

<style scoped lang="scss">
td, th {
  border-right: 1px solid #cecece;
  border-bottom: 1px dotted #cecece;
}


.place {
  width: 40px;
  text-align: left;
  margin-left: 0;
  flex: 0 0 40px;
}

</style>
